import { useCallback } from 'react';
import { Box, Text, HStack, Tag, Flex, Switch } from '@common/components';

const TableHeader = ({ category, tab, number, onChange }) =>
{
    const handleToggle = useCallback((event) => {
        onChange(event.target.checked ? 'dimensions' : category === 'SPORTS' ? 'troublesome' : 'competences');
    }, [category, onChange]);
    return (
        <Box minH="68px" borderBottom="1px solid" borderBottomColor="#EAECF0">
            <Flex>
                <HStack px="24px" py="20px" flex="1">
                    <Text fontSize="18px" fontWeight="500" color="text">Personas</Text>
                    <Tag borderRadius="full" fontFamily="Inter" fontSize="12px" color="#5A81A4">{number}</Tag>
                </HStack>
                <HStack pr="20px">
                    <Text fontSize="14px" fontWeight="500"
                    color={tab === 'competences' ? '#222C45' : '#9CA6C1'}>Competencias</Text>
                    <Switch isChecked={tab === 'dimensions'} colorScheme="buttons.primary" onChange={handleToggle} />
                    <Text fontSize="14px" fontWeight="500"
                    color={tab === 'dimensions' ? '#222C45' : '#9CA6C1'}>Dimensiones personalidad</Text>
                </HStack>
            </Flex>
        </Box>
    );
}

export default TableHeader;