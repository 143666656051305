import { useState, useCallback } from 'react';
import { useQueryParam, StringParam } from 'use-query-params';
import { useAuth } from '@common/providers/Auth';
import { useToast } from '@chakra-ui/react';

import { UserRepository } from '@repositories';

import RecoverAccount from './components/RecoverAccount';
import RecoveredAccount from './components/RecoveredAccount';

const Recover = () =>
{
    const { onSignIn } = useAuth();
    const toast = useToast();
    const [email] = useQueryParam('email', StringParam);
    const [isRecovering, setIsRecovering] = useState(false);
    const [isRecovered, setIsRecovered] = useState(false);
    const [isAuthenticating, setIsAuthenticating] = useState(false);
    const [code, setCode] = useState('');
    const handleChangeCode = (value) => setCode(value);
    const [password, setPassword] = useState('');
    const handleChangePassword = (value) => setPassword(value);
    const [confirm, setConfirm] = useState('');
    const handleChangeConfirm = (value) => setConfirm(value);
    const handleRecover = useCallback(async () => {
        if (code.length === 0) return;
        if (confirm.length === 0 || password.length === 0) return;
        if (confirm !== password) return;
        setIsRecovering(true);
        try {
            await UserRepository.setPassword({ email, code, password });
            setIsRecovered(true);  
        } catch (error) {
            toast({
                title: 'No hemos podido reestablecer tu contraseña. Ponte en contacto con soporte@smartesglobal.com.',
                status: 'error',
                duration: null,
                isClosable: true,
                position: 'top'
            });
        }
        setIsRecovering(false);
    }, [email, code, password, confirm, toast]);
    const handleSignIn = useCallback(async () => {
        setIsAuthenticating(true);
        onSignIn({ email, password });
    }, [email, password, onSignIn]);
    if (isRecovered) return <RecoveredAccount isLoading={isAuthenticating} onSignIn={handleSignIn} />
    return (
        <RecoverAccount
        code={code} onChangeCode={handleChangeCode}
        password={password} onChangePassword={handleChangePassword}
        confirm={confirm} onChangeConfirm={handleChangeConfirm}
        isLoading={isRecovering}
        onRecover={handleRecover}
        />
    );
}

export default Recover;