import { Button } from '@common/components';

const SelectButton = ({ children, isActive, onClick }) =>
(
    <Button
    variant="select"
    isActive={isActive}
    onClick={onClick}>
    {children}
    </Button>
);


export default SelectButton;