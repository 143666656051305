import { Center } from '@common/components';
import { Container } from '@common/layouts';

const FullContainer = ({ children, footer, pb }) =>
(
    <Container maxW="1216px" minH="100vh" centerContent>
        <Center minH="100vh" pt="40px" pb={ pb ? pb : '120px'} minW="100%">
        {children}
        </Center>
        {footer ? footer : null}
    </Container>
);

export default FullContainer;