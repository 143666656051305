export class Team
{
    constructor ({
        id,
        name,
        logo,
        web,
        description,
        socialmedia,
        persons,
        status,
        score,
        config,
        category
    }) {
        this.id = id;
        this.name = name;
        this.logo = logo;
        this.web = web;
        this.description = description;
        this.socialmedia = socialmedia;
        this.persons = persons;
        this.status = status;
        this.score = score;
        this.config = config;
        this.category = category;
    }
    static fromJson (json) {
        if (!json) return null;
        return new Team({
            id: json.id,
            name: json.name,
            logo: json.logo,
            web: json.web,
            description: json.description,
            socialmedia: json.socialmedia,
            persons: json.persons,
            status: json.status,
            score: json.score,
            config: {
                locale: json.config?.locale
            },
            category: json.category
        });
    }
};