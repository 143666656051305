import React, { useMemo } from 'react';
import { BrowserRouter, useNavigate, useLocation } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';

import './localization/i18n';

import { ChakraProvider as Theme } from '@chakra-ui/react';
import { theme } from './styles/theme';

import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

import App from './App.Routes';
import ScrollToTop from './App.Scroll';

import { Global, css } from '@emotion/react';

const GlobalStyles = css`
/*
    This will hide the focus indicator if the element receives focus via the mouse,
    but it will still show up on keyboard focus.
*/
    .js-focus-visible :focus:not([data-focus-visible-added]) {
        outline: none;
        box-shadow: none;
    }
`;

Bugsnag.start({ apiKey: process.env.REACT_APP_BUGSNAG, plugins: [new BugsnagPluginReact()], appVersion: process.env.REACT_APP_VERSION });
const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

const RouteAdapter = ({ children }) =>
{
    const navigate = useNavigate();
    const location = useLocation();
    const adaptedHistory = useMemo(() => ({
        replace (location) { navigate(location, { replace: true, state: location.state }); },
        push (location) { navigate(location, { replace: false, state: location.state }); }
    }), [navigate]);
    return children({ history: adaptedHistory, location });
};

const Root = () =>
(
    <ErrorBoundary>
        <BrowserRouter>
            <QueryParamProvider ReactRouterRoute={RouteAdapter}>
                <Theme theme={theme}>
                    <Global styles={GlobalStyles} />
                    <ScrollToTop />
                    <App />
                </Theme>
            </QueryParamProvider>
        </BrowserRouter>
    </ErrorBoundary>
);

export default Root;