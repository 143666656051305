import { Api, Storage } from '@services';

export const UserRepository =
{
    signUp: async ({ token, password }) => {
        const response = await Api.signUp({ token, password });
        return response.data.content.email;
    },
    signIn: async ({ email, password }) => {
        const response = await Api.signIn({ email, password });
        Storage.setSession({
            email,
            client: response.data.content.client,
            session: response.data.content.session
        });
        return response.data.content;
    },
    signOut: () => {
        Storage.deleteSession();
    },
    verify: async ({ email, code }) => {
        return await Api.verifyAccount({ email, code });
    },
    getSession: () => {
        return Storage.getSession();
    },
    updatePassword: async ({ oldPassword, newPassword }) => {
        const session = Storage.getSession();
        const client = Storage.getClientId();
        await Api.updatePassword({ client, session, body: { oldPassword, newPassword }});
    },
    reset: async ({ email }) => {
        return await Api.resetPassword({ body: { email }});
    },
    setPassword: async ({ email, code, password }) => {
        return await Api.setPassword({ body: { email, code, password }});
    }
};