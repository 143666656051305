import { useState, useCallback } from 'react';
import { useToast } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import links from '@common/links';

import { UserRepository } from '@repositories';

import ForgotAccount from './components/ForgotAccount';
import ResetAccount from './components/ResetAccount';

const Forgot = () =>
{
    const navigate = useNavigate();
    const toast = useToast();
    const [isReseting, setIsReseting] = useState(false);
    const [isReseted, setIsReseted] = useState(false);
    const [email, setEmail] = useState('');
    const handleChangeEmail = (value) => setEmail(value);
    const handleResetAccount = useCallback(async () => {
        if (email.length === 0) return;
        setIsReseting(true);
        try {
            await UserRepository.reset({ email });
            setIsReseted(true);  
        } catch (error) {
            toast({
                title: 'No hemos podido reestablecer tu contraseña. Ponte en contacto con soporte@smartesglobal.com.',
                status: 'error',
                duration: null,
                isClosable: true,
                position: 'top'
            });
        }
        setIsReseting(false);
    }, [email, toast]);
    const handleSetPassword = useCallback(() => {
        navigate(links.setpassword(email));
    }, [email, navigate]);

    if (isReseted) return <ResetAccount email={email} onReset={handleResetAccount} onSet={handleSetPassword} />;
    return (
        <ForgotAccount
        email={email} onChangeEmail={handleChangeEmail}
        isReseting={isReseting}
        onReset={handleResetAccount}
        />
    );
}

export default Forgot;