import { Box, Text } from '@common/components';

const TeamPanelInfo = ({ text, subtext }) =>
(
    <Box>
        <Text fontFamily="Inter" fontSize="14px" fontWeight="500" color="#344054">
        {text}
        </Text>
        <Text fontFamily="Inter" fontSize="14px" fontWeight="400" color="#667085">
        {subtext}
        </Text>
    </Box>
);

export default TeamPanelInfo;