import { useCallback, useState } from 'react';
import { Box, Flex, Text, FormInput, FormInputGroup, FormInputLeftAddon, Textarea, Spacer, Button } from '@common/components';
import TeamPanel from './TeamPanel';
import TeamPanelInfo from './TeamPanelInfo';
import TeamDivider from './TeamDivider';
import Uploader from './Uploader';

const TeamDescription = ({ isAdding, onAdd, name, onChangeName, web, onChangeWeb, description, onChangeDescription, twitter, onChangeTwitter, instagram, onChangeInstagram, linkedin, onChangeLinkedin, logo, onChangeLogo }) =>
{
    const [chars, setChars] = useState(40);
    const handleDescription = useCallback((event) => {
        const text = event.target.value;
        if (text.length <= 40) {
            setChars(40 - text.length);
            onChangeDescription(text);
        }
    }, [onChangeDescription]);
    return (
        <Box mt="32px" id="addteam-form">
            <TeamPanel
            info={
                <TeamPanelInfo
                text="Nombre del equipo"
                subtext="Esto se mostrará en la página del equipo"
                />
            }
            form={
                <Box>
                <FormInput
                value={name} onChange={(event) => { onChangeName(event.target.value); }}
                isDisabled={isAdding}
                focusBorderColor="#70B37D" color="#667085" bg="white" maxW="512px"
                placeholder="Introduce un nombre..."
                />
                <FormInputGroup maxW="512px" mt="16px">
                    <FormInputLeftAddon fontSize="14px" color="#667085" bg="white"
                    children="https://" />
                    <FormInput
                    value={web} onChange={(event) => { onChangeWeb(event.target.value); }}
                    isDisabled={isAdding}
                    focusBorderColor="#70B37D" color="#667085" bg="white"
                    placeholder="Introduce una web..." />
                </FormInputGroup>
                </Box>
            }
            />
            <TeamPanel
            mt="20px"
            info={
                <TeamPanelInfo
                text="Misión"
                subtext="Propósito del equipo"
                />
            }
            form={
                <Box>
                <Textarea
                value={description} onChange={handleDescription}
                isDisabled={isAdding}
                focusBorderColor="#70B37D" color="#667085" bg="white"
                maxW="512px" h="100px" resize="none"
                placeholder="Introduce una descripción..."
                />
                <Text mt="6px" fontSize="14px" fontWeight="400" color="#667085">
                {`Quedan ${chars} caracteres`}
                </Text>
                </Box>
            }
            />
            <TeamPanel
            mt="20px"
            info={
                <TeamPanelInfo
                text="Logo del equipo"
                subtext="Actualiza el logotipo del equipo"
                />
            }
            form={
                <Uploader
                logo={logo}
                onChangeLogo={onChangeLogo}
                />
            }
            />
            <TeamPanel
            mt="20px"
            info={
                <TeamPanelInfo
                text="Perfiles sociales"
                />
            }
            form={
                <Box>
                <FormInputGroup maxW="512px" mt="16px">
                    <FormInputLeftAddon fontSize="14px" color="#667085" bg="white"
                    children="twitter.com/" />
                    <FormInput
                    value={twitter} onChange={(event) => { onChangeTwitter(event.target.value); }}
                    isDisabled={isAdding}
                    focusBorderColor="#70B37D" color="#667085" bg="white"  />
                </FormInputGroup>
                <FormInputGroup maxW="512px" mt="16px">
                    <FormInputLeftAddon fontSize="14px" color="#667085" bg="white"
                    children="instagram.com/" />
                    <FormInput
                    value={instagram} onChange={(event) => { onChangeInstagram(event.target.value); }}
                    isDisabled={isAdding}
                    focusBorderColor="#70B37D" color="#667085" bg="white"  />
                </FormInputGroup>
                <FormInputGroup maxW="512px" mt="16px">
                    <FormInputLeftAddon fontSize="14px" color="#667085" bg="white"
                    children="linkedin.com/company/" />
                    <FormInput
                    value={linkedin} onChange={(event) => { onChangeLinkedin(event.target.value); }}
                    isDisabled={isAdding}
                    focusBorderColor="#70B37D" color="#667085" bg="white"  />
                </FormInputGroup>
                </Box>
            }
            />
            <TeamDivider mt="24px" />
            <Flex>
                <Spacer />
                <Button 
                id="addteam-form-button"
                colorScheme="buttons.primary" fontWeight="500"
                isDisabled={name.length === 0}
                isLoading={isAdding}
                onClick={onAdd}
                >
                Guardar
                </Button>
            </Flex>
        </Box>
    );
};

export default TeamDescription;