import { useTranslation } from 'react-i18next';
import { Box, HeadingText } from '@common/components';

const Title = () =>
{
    const { t } = useTranslation();
    return (
        <Box mt="73px">
            <HeadingText>{t('invitation.form.title')}</HeadingText>
        </Box>
    );
}

export default Title;