import { Flex, Spacer, HStack, Icon, Button } from '@common/components';

const SignOut = ({ onSignOut }) =>
(
    <Flex mt="60px">
        <Spacer />
        <HStack spacing="12px">
            <Button variant="underline" onClick={onSignOut}>
            Log-out 
            </Button>
            <Icon icon="logout" color="#9CA6C1" size="20px" />
        </HStack>
    </Flex>
);

export default SignOut;