import { Box, FormInput, FormInputGroup, FormInputLeftElement, Icon, Flex, Spacer, Text, HStack } from '@common/components';
import Section from './Section';
import Panel from './Panel';

const PersonalData = ({ client }) =>
(
    <Box>
        <Section title="Información personal" subtitle="Actualiza tus datos personales aquí." />
        <Box h="1px" bg="#EAECF0" />
        <Panel info="Empresa" form={
            <FormInput maxW="512px"
            value={client.name} isDisabled
            color="#667085" bg="white"  />
        } />
        <Box h="1px" bg="#EAECF0" />
        <Panel info="Correo electrónico" form={
            <FormInputGroup>
                <FormInputLeftElement
                pointerEvents='none'
                children={<Icon icon="email" color="#C4C4C4" size="16px" />}
                />
                <FormInput maxW="512px"
                value={client.email} isDisabled
                color="#667085" bg="white"  />
            </FormInputGroup>
        } />
        <Box h="1px" bg="#EAECF0" />
        <Flex mt="16px">
            <Spacer />
            <HStack spacing="5px">
                <Text fontSize="14px" color="#667085">
                Si quieres editar esta información ponte en contacto con
                </Text>
                <Text fontSize="14px" color="#667085" fontWeight="500">soporte@smartestglobal.com</Text>
            </HStack>
        </Flex>
    </Box>
);

export default PersonalData;