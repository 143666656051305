import '../index.css';
import { extendTheme } from '@chakra-ui/react';
import 'focus-visible/dist/focus-visible';

export const theme = extendTheme({
    fonts: {
        heading: 'Inter, sans-serif',
        body: 'Inter, sans-serif'
    },
    colors: {
        red: {
            500: '#8A5DB7',
        },
        text: '#101828',
        link: '#667085',
        primaries: {
            blue: '#222C45',
            green: '#70B37D'
        },
        secondaries: {
            white: '#F9F9F9',
            gray: '#C4C4C4',
            brown: '#25201B',
            blue: '#5A81A4'
        },
        others: {
            divider: '#E7ECF9',
            border: '#D5DBEA'
        },
        buttons: {
            primary: {
                50: '#6B7798',
                100: '#6B7798',
                200: '#6B7798',
                300: '#6B7798',
                400: '#6B7798',
                500: '#6B7798',
                600: '#222C45',
                700: '#222C45',
                800: '#222C45',
                900: '#222C45'
            },
            ghost: {
                50: '#FFFFF',
                100: '#FFFFF',
                200: '#FFFFF',
                300: '#FFFFF',
                400: '#FFFFF',
                500: '#FFFFF',
                600: '#F5F8FF',
                700: '#F5F8FF',
                800: '#F5F8FF',
                900: '#F5F8FF'
            },
            secondary: {
                50: '#70B37D',
                100: '#70B37D',
                200: '#70B37D',
                300: '#70B37D',
                400: '#70B37D',
                500: '#70B37D',
                600: '#439C55',
                700: '#439C55',
                800: '#439C55',
                900: '#439C55'
            }
        }
    },
    components: {
        Button: {
            variants: {
                primary: {
                    bg: 'transparent',
                    fontWeight: '500',
                    fontFamily: 'Inter',
                    fontSize: '16px',
                    color: '#566283',
                    _hover: {
                        color: "#222C45", 
                        bg: "#F5F8FF",
                        borderColor: "#A5CBAC" 
                    },
                    _active: {
                        color: "#222C45",
                        bg: "#F5F8FF",
                        borderColor: "#A5CBAC" 
                    }
                },
                secondary: {
                    bg: 'transparent',
                    border: '1px solid',
                    borderColor: '#D5DBEA',
                    fontWeight: '500',
                    fontFamily: 'Inter',
                    fontSize: '16px',
                    color: '#566283',
                    _hover: {
                        color: "#222C45", 
                        bg: "#F6FEF9",
                        borderColor: "#A5CBAC" 
                    },
                    _active: {
                        color: "#222C45",
                        bg: "#F6FEF9",
                        borderColor: "#A5CBAC" 
                    }
                },
                outline: {
                    bg: '#FFFFFF',
                    border: '1px solid',
                    boderColor: '#D0D5DD',
                    fontWeight: '500',
                    fontFamily: 'Inter',
                    fontSize: '16px',
                    color: '#344054',
                    _hover: {
                        color: "#344054", 
                        bg: "#FFFFFF",
                        borderColor: "#344054" 
                    },
                    _active: {
                        color: "#344054",
                        bg: "#FFFFFF",
                        borderColor: "#344054" 
                    }
                },
                select: {
                    bg: 'transparent',
                    fontWeight: '500',
                    fontFamily: 'Inter',
                    fontSize: '18px',
                    color: '#566283',
                    _hover: {
                        color: "#222C45", 
                        bg: "#F5F8FF",
                        borderColor: "#A5CBAC" 
                    },
                    _active: {
                        color: "#222C45",
                        bg: "#F5F8FF",
                        borderColor: "#A5CBAC" 
                    }
                },
                underline: {
                    padding: '0px',
                    textDecoration: 'underline',
                    bg: 'transparent',
                    fontWeight: '500',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                    color: '#9CA6C1',
                    _hover: {
                        color: "#222C45"
                    },
                    _active: {
                        color: "#222C45"
                    }
                }
            }
        }
    }
});