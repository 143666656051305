import { Modal, ModalOverlay, ModalContent, ModalCloseButton, Center, Text, Icon } from '@common/components';

const ModalUnsavedData = ({ isOpen, onClose }) =>
(
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent p="24px">
            <ModalCloseButton />
            <Center bg="#F6FEF9" borderRadius="full" boxSize="56px">
                <Icon icon="success" color="#70B77E" size="23px" />
            </Center>
            <Text mt="20px" fontSize="18px" fontWeight="500" color="#101828">Invitaciones enviadas.</Text>
            <Text mt="10px" fontSize="14px" fontWeight="400" color="#667085">En la página de este equipo podrás ver el estado de cada uno de los miembros a los que has invitado.</Text>
        </ModalContent>
    </Modal>
);

export default ModalUnsavedData;