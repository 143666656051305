import { Modal, ModalOverlay, ModalContent, VStack, Center, Text, Icon, Box, HStack, Button } from '@common/components';

const ModalUnsavedData = ({ isOpen, onClose, onSave }) =>
(
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent p="24px">
            <VStack spacing="20px" textAlign="center">
                <Center bg="#FFE9BF" borderRadius="full" boxSize="56px">
                    <Icon icon="warning" color="#CF8804" size="23px" />
                </Center>
                <Box>
                    <Text fontSize="18px" fontWeight="500" color="#25201B">Datos sin guardar</Text>
                    <Text fontSize="14px" fontWeight="400" color="link">Guarda los datos antes de invitar a los miembros al equipo.</Text>
                </Box>
            </VStack>
            <HStack spacing="12px" pt="32px">
                <Button w="100%" variant="outline"
                fontWeight="500"
                onClick={onClose}
                >
                Cancelar
                </Button>
                <Button w="100%" colorScheme="buttons.primary"
                fontWeight="500"
                onClick={onSave}
                >
                Guardar
                </Button>
            </HStack>
        </ModalContent>
    </Modal>
);

export default ModalUnsavedData;