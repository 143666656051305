import { Box, TableContainer, Table, Thead, Tr, Th, Tbody, Text } from '@common/components';
import TeamsTableHeader from './TeamsTableHeader';
import TeamsTableRow from './TeamsTableRow';

const TeamsTable = ({ teams }) =>
(
    <Box id="teams-table" border="1px solid" borderColor="#EAECF0" borderRadius="8px" mt="40px">
        <TeamsTableHeader number={teams.length} />
        <TableContainer>
            <Table colorScheme="gray">
            <Thead>
                <Tr>
                    <Th>
                    <Text fontSize="12px" fontFamily="Inter" color="#667085">Nombre</Text>
                    </Th>
                    <Th>
                    <Text fontSize="12px" fontFamily="Inter" color="#667085">Propósito del equipo</Text>
                    </Th>
                    <Th>
                    <Text fontSize="12px" fontFamily="Inter" color="#667085">Personas</Text>
                    </Th>
                    <Th>
                    <Text fontSize="12px" fontFamily="Inter" color="#667085">Estado del equipo</Text>
                    </Th>
                </Tr>
            </Thead>
            <Tbody>
            {teams.map((team, index) => 
                <TeamsTableRow
                index={index}
                key={team.id}
                team={team}
                />
            )}  
            </Tbody>
            </Table>
        </TableContainer>
    </Box>
);

export default TeamsTable;