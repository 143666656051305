import { useCallback } from 'react';
import { Box } from '@chakra-ui/react';

const SelectedTab = ({ children, onClick }) =>
(
    <Box
    as="button"
    height="51px"
    borderBottom="2px"
    borderColor="#70B37D"
    fontFamily="Inter"
    fontSize='16px'
    bg="transparent"
    color="#70B37D"
    _hover={{
        color: "#70B37D"
    }}
    _focus={{
        color: "#70B37D",
        borderBottom: "2px",
        borderColor: "#70B37D"
    }}
    onClick={onClick}
    >
    {children}
    </Box>
);

const UnselectedTab = ({ children, isDisabled, onClick }) =>
(
    <Box
    as="button"
    height="51px"
    borderBottom="2px"
    borderColor="transparent"
    fontFamily="Inter"
    fontSize='16px'
    bg="transparent"
    color="#667085"
    disabled={isDisabled}
    _hover={{
        color: "#70B37D"
    }}
    _focus={{
        color: "#667085"
    }}
    onClick={onClick}
    >
    {children}
    </Box>
);

const Tab = ({ id, isSelected, isDisabled, onChange, children }) =>
{
    const handleClick = useCallback(() => {
        if (onChange) onChange(id);
    }, [id, onChange]);
    if (isSelected) return <SelectedTab children={children} onClick={handleClick} />
   return <UnselectedTab isDisabled={isDisabled} children={children} onClick={handleClick} />
};

export default Tab;