import _ from 'lodash';
import { Box, HStack, Flex, Center, Icon, SimpleGrid, Divider, Text } from '@common/components';
import Dataset from './Dataset';

const VerticalAxis = ({ legend }) =>
(
    <Flex w="24px" h="100%" direction="column">
        <Center w="100%" h="24px" bg="#F6FEF9" borderRadius="12px">
            <Icon icon="success" size="10px" color="#319044" />
        </Center>
        <Center flex="1">
            <Text fontSize="16px" color="link" style={{ transform: 'rotate(-90deg)' }}>
            {legend}
            </Text>
        </Center>
        <Center w="100%" h="24px" bg="#F3EFF8" borderRadius="12px">
            <Icon icon="danger" size="10px" color="#8A5DB7" />
        </Center>
    </Flex>
);

const HorizontalAxis = ({ legend }) =>
(
    <Flex flex="1" h="24px">
        <Center w="24px" h="100%" bg="#F3EFF8" borderRadius="12px">
            <Icon icon="danger" size="10px" color="#8A5DB7" />
        </Center>
        <Center flex="1">
            <Text fontSize="16px" color="link">
            {legend}
            </Text>
        </Center>
        <Center w="24px" h="100%" bg="#F6FEF9" borderRadius="12px">
            <Icon icon="success" size="10px" color="#319044" />
        </Center>
    </Flex>
);

const GridBackground = ({ status, color, display }) =>
{
    switch (status) {
        case 'success': color = '#70B77E'; break;
        case 'warning': color = '#F6AD23'; break;
        case 'danger': color = '#8A5DB7'; break;
        default: color = '#D0D5DD';
    }
    return (
        <SimpleGrid w="100%" h="100%"
        border="2px solid" borderRadius="8px" borderColor={color}
        columns={2}
        spacing="0px"
        >
            <Box bg={display === 1 ? '#F6FEF9' : '#F6FEF9'} borderTopLeftRadius="8px"></Box>
            <Box bg={display === 1 ? '#F3EFF8' : '#F6FEF9'} borderTopRightRadius="8px"></Box>
            <Box bg={display === 1 ? '#F6FEF9' : '#F3EFF8'} borderBottomLeftRadius="8px"></Box>
            <Box bg={display === 1 ? '#F6FEF9' : '#F6FEF9'} borderBottomRightRadius="8px"></Box>
        </SimpleGrid>
    );
}

const Dividers = () =>
(
    <Box w="100%" h="100%" p="2px" style={{ position: 'absolute' }}>
        <Divider variant="dashed" h="100%" orientation="vertical" ml="50%" />
        <Divider variant="dashed" w="100%" mt="-174px" />
    </Box>
);

const VerticalScale = () =>
(
    <Flex h="100%" direction="column" pl="8px" style={{ position: 'absolute' }} textAlign="right">
    {[...Array(11)].map((x, i) => 
        <Text mt="8px" flex="1" color="#98A2B3" fontSize="10px" key={i}>{`${100 - (i * 10)}%`}</Text>
    )}
    </Flex>
);

const HorizontalScale = () =>
(
    <Flex w="100%" pl="19px" pb="8px" style={{ position: 'absolute', bottom: '0' }}>
    {[...Array(11)].map((x, i) => 
        <Text flex="1" color="#98A2B3" fontSize="10px" key={i}>{i !== 0 ? `${i * 10}%` : ''}</Text>
    )}
    </Flex>
);

const Chart = ({ display, status, competences }) =>
{
    const calculatePosition = (x, y) => {
        if (x <= 50 && y <= 50) return 1;
        else if (x > 50 && y <= 50) return 2;
        else if (x <= 50 && y > 50) return 3;
        else return 4;
    };
    const data = _.compact(competences.map((profile) => {
        if (!profile.troublesome || profile.person.status === 'distortioned') return null;
        if (!profile.competences || profile.person.status === 'distortioned') return null;
        const x = display === 1 ? profile.troublesome?.unstable : profile.competences?.teamwork;
        const y = display === 1 ? profile.troublesome?.troublesome : profile.competences?.leadership;
        return {
            tooltip: profile.person?.name,
            label: profile.person?.name,
            x,
            y,
            quarter: calculatePosition(x, y),
            email: profile.person?.email
        };
    }));
    const quarters = Object.values(_.groupBy(data, 'quarter'));
    const datapoints = quarters.map((quarter) => {
        const groups = [];
        const groupByDistance = _.uniqWith(quarter, (arrVal, othVal) => {
            const dist = Math.sqrt(Math.pow(arrVal.x - othVal.x, 2) + Math.pow(arrVal.y - othVal.y, 2));
            if (dist > 5) return false;
            groups.push({arrVal, othVal});
            return true;
        });
        if (groupByDistance.length === quarter.length) return quarter;
        return groupByDistance.map((profile) => {
            const filtered = groups.filter((element) => element.arrVal.label === profile.label || element.othVal.label === profile.label);
            if (filtered.length === 0) return profile;
            return {
                tooltip: `${profile.label} ${filtered.map((group) => {
                    const name = group.arrVal.label !== profile.label ? group.arrVal.label : group.othVal.label;
                    return `| ${name}`;
                })}`,
                label: `+${filtered.length + 1}`,
                x: profile.x,
                y: profile.y,
                quarter: profile.quarter,
                email: profile.email
            };
        });
    });
    const legendY = display === 1 ? 'Conflictividad' : 'Liderazgo';
    const legendX = display === 1 ? 'Reactividad' : 'Colaboración';
    return (
        <Box minH="384px">
            <HStack h="350px" spacing="0px">
                <VerticalAxis legend={legendY} />
                <Flex w="10px" />
                <Flex flex="1" h="100%" style={{ position: 'relative' }}>
                    <GridBackground status={status} display={display} />
                    <Dividers />
                    <VerticalScale />
                    <HorizontalScale />
                    <Dataset data={_.flatten(datapoints)} />
                </Flex>
            </HStack>
            <Box h="10px" />
            <HStack spacing="0px">
                <Flex w="34px" />
                <HorizontalAxis legend={legendX} />
            </HStack>
        </Box>
    );
}

export default Chart;