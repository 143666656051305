import * as FeatherIcon from 'react-feather';

const Icon = ({ icon, color = "black", size = "14px" }) =>
{
    switch (icon) {
        case 'zap': return <FeatherIcon.Zap color={color} size={size} />;
        case 'key': return <FeatherIcon.Key color={color} size={size} />;
        case 'arrow-left': return <FeatherIcon.ArrowLeft color={color} size={size} />;
        case 'email': return <FeatherIcon.Mail color={color} size={size} />;
        case 'check': return <FeatherIcon.CheckCircle color={color} size={size} />;
        case 'lock': return <FeatherIcon.Lock color={color} size={size} />;
        case 'success': return <FeatherIcon.CheckCircle color={color} size={size} />;
        case 'warning': return <FeatherIcon.AlertTriangle color={color} size={size} />;
        case 'danger': return <FeatherIcon.AlertCircle color={color} size={size} />;
        case 'plus': return <FeatherIcon.Plus color={color} size={size} />;
        case 'help': return <FeatherIcon.HelpCircle color={color} size={size} />;
        case 'upload': return <FeatherIcon.UploadCloud color={color} size={size} />;
        case 'logout': return <FeatherIcon.LogOut color={color} size={size} />;
        case 'show-password': return <FeatherIcon.Eye color={color} size={size} />;
        case 'hide-password': return <FeatherIcon.EyeOff color={color} size={size} />;
        case 'external-link': return <FeatherIcon.ExternalLink color={color} size={size} />;
        case 'chevron-down': return <FeatherIcon.ChevronDown color={color} size={size} />;
        default: return null;
    }
};

export default Icon;