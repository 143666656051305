import { Box, InputForm, Button } from '@common/components';

const ForgotForm = ({ code, onChangeCode, onVerify, isVerifying }) =>
(
    <Box w="100%" fontSize="16px">
        <InputForm id="code"
        type="text"
        text="Código de verificación"
        placeholder="Introduce tu código de verificación"
        value={code}
        onChange={onChangeCode}
        />
        <Button w="100%" colorScheme="buttons.primary" mt="24px"
        fontWeight="500"
        isLoading={isVerifying}
        onClick={onVerify}
        >
        Verificar cuenta
        </Button>
    </Box>
);

export default ForgotForm;