import { useRef, useState, useCallback } from 'react';
import { TeamRepository } from '@repositories';
import { CircularProgress, Flex, Box, VStack, Center, Icon, Text, Avatar } from '@common/components';

const Uploader = ({ logo, onChangeLogo }) =>
{
    const [upload, setUpload] = useState(0);
    const [isUploading, setIsUploading] = useState(false);
    const [color, setColor] = useState('green.300');
  
    const inputRef = useRef();
    const onFileUpload = useCallback(() => {
        if (!isUploading) inputRef.current.click();
    }, [isUploading]);
    const onFileChange = useCallback(async (e) => {
        if (!e.target.files[0]) return;
        setIsUploading(true);
        try {
            const file = e.target.files[0];
            const url = await TeamRepository.uploadLogo(file);
            onChangeLogo(url);
            setIsUploading(false);
        } catch (error) {
            setIsUploading(false);
            setColor('red.300');
            setUpload(100);
            setTimeout(() => { setUpload(0); }, 3000);
        }
    }, [onChangeLogo]);

    return (
        <Flex maxW="512px">
            {
                logo
                ? <Avatar size="md" src={logo} bg="white" border="1px solid #C4C4C4" />
                : <CircularProgress isIndeterminate={isUploading} value={upload} color={color} />
            }
            <input hidden ref={inputRef} type="file" onChange={onFileChange} accept="image/png,image/gif,image/jpeg" />
            <Box ml="30px" flex="1" as="button" bg="white" border="1px solid #E2E8F0" borderRadius="8px" p="16px" onClick={onFileUpload}>
                <VStack spacing="12px" textAlign="center">
                    <Center bg="#F7FAFC" borderRadius="full" boxSize="40px">
                        <Icon icon="upload" color="#475467" size="18px" />
                    </Center>
                    <Text color="#222C45" fontWeight="500" fontSize="14px">Haz clic para cargar</Text>
                    <Text color="#667085" fontSize="12px">SVG, PNG, JPG o GIF (max. 1MB)</Text>
                </VStack>
            </Box>
        </Flex>
    );
};

export default Uploader;
