import { useCallback, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import links from '@common/links';

import { Box, Text, Link, Checkbox, Button, Language, Flex, Spacer, Image } from '@common/components';

const TermsData = ({ client, logo, isFilled, isDisabled, onAccept }) =>
{
    const { t, i18n } = useTranslation();
    const [isAccepted, setIsAccepted] = useState(false);
    const handleChangeLanguage = useCallback((lang) => {
        i18n.changeLanguage(lang);
    }, [i18n]);
    return (
        <Box w="100%" textAlign="left" maxW="736px" px="24px" mt="73px">
            {logo && <Image src={logo} alt={client} maxW="180px" mb="20px" />}
            <Flex>
                <Text fontSize="30px" fontWeight="500" color="#25201B">
                {t('invitation.terms.title')}
                </Text>
                <Spacer />
                <Language value={i18n.language} onChange={handleChangeLanguage} />
            </Flex>
            <Text mt="24px" fontSize="16px" fontWeight="400" color="#344054">
            {!isFilled
            ? <>
                <Trans i18nKey="invitation.terms.description.part1" />
                <span style={{fontWeight: 600}}>
                {t('invitation.terms.description.part3')}
                </span><br/><br/>
            </>
            : <Trans i18nKey="invitation.terms.description.part4" />
            }
            {t('invitation.terms.description.part2')}{' '}
            <Link href={links.termsandconditions} isExternal
            fontFamily="Inter" fontSize="16px" fontWeight="400"
            color="#70B37D">
            [{t('invitation.terms.description.link')}]→
            </Link>
            </Text>
            <Checkbox mt="24px" p="16px" pr="24px"
            border="1px solid #EAECF0" borderRadius="8px" 
            colorScheme="green" fontSize="16px" fontWeight="500" color="#344054"
            onChange={(event) => { setIsAccepted(event.target.checked); }} >
            {t('invitation.terms.action.accept')}
            </Checkbox>
            <Box mt="24px">
                <Button colorScheme="buttons.secondary"
                fontWeight="500"
                isDisabled={!isAccepted || isDisabled}
                onClick={onAccept}>
                {!isFilled ? t('invitation.terms.action.continue') : t('invitation.terms.action.include')}
                </Button>
            </Box> 
        </Box>
    );
};

export default TermsData;