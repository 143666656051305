import { Td, Text } from '@common/components';

const PendingStatus = ({ cols = 4 }) =>
(
    <Td colSpan={cols}>
        <Text as="i" fontFamily="Inter" fontSize="14px" fontWeight="500" color="#667085">
        Esta persona aún no ha completado el test
        </Text>
    </Td>
);

export default PendingStatus;