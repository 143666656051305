import { Box, Text, HStack, Tag } from '@common/components';

const TeamsTableHeader = ({ number }) =>
(
    <Box minH="68px" borderBottom="1px solid" borderBottomColor="#EAECF0">
        <HStack px="24px" py="20px">
            <Text fontSize="18px" fontWeight="500" color="text">Equipos</Text>
            <Tag borderRadius="full" fontFamily="Inter" fontSize="12px" color="#5A81A4">{number}</Tag>
        </HStack>
    </Box>
);

export default TeamsTableHeader;