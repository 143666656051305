import { Box, Flex, Spacer, HStack, VStack, Button, Textarea, Text, Language } from '@common/components';
import TeamPanel from './TeamPanel';
import TeamPanelInfo from './TeamPanelInfo';
import TeamDivider from './TeamDivider';

const TeamMembers = ({ members, invitations, onChangeMembers, isSending, onSend, onReset, locale, onChangeLocale }) =>
{
    return (
        <Box mt="32px">
            <TeamPanel
            info={
                <>
                <TeamPanelInfo
                text="Invita a las personas del equipo"
                subtext="Introduce las direcciones de correo electrónico de los miembros del equipo a continuación y recibirán invitaciones para realizar el test."
                />
                <Text my="20px" fontSize="14px" fontWeight="400" style={{textDecoration: 'underline'}}>Idioma en el que se enviarán las invitaciones:</Text>
                <Language value={locale} onChange={onChangeLocale} />
                </>
            }
            form={
                <Flex>
                    <HStack spacing="0px"  flex=".75">
                        <VStack  bg="#EAEAEA" pl="25px" pr="6px" py="6px" h="260px">
                        {[1, 2, 3, 4, 5, 6, 7, 8].map((member, index) =>
                            <Text key={member} fontWeight="500" color="#101828">{index + 1}</Text>)
                        }
                        </VStack>
                        <Textarea
                        value={members} onChange={(event) => { onChangeMembers(event.target.value); }}
                        isDisabled={isSending}
                        lineHeight="32px" py="0px"
                        focusBorderColor="#70B37D" color="#667085" bg="white"
                        borderRadius="0px"
                        maxW="512px" h="260px" resize="none"
                        placeholder="nombre@equipo.com"
                        />
                    </HStack>
                    <Box ml="32px">
                        <Text fontFamily="Inter" fontSize="14px" fontWeight="500" color="#344054">
                        Invitaciones enviadas
                        </Text>
                        {invitations.map((email) => 
                            <Text key={email} fontFamily="Inter" fontSize="14px" fontWeight="400" color="#667085">
                            {email}
                            </Text>
                        )}
                    </Box>
                </Flex>
            }
            />
            <TeamDivider mt="24px" />
            <Flex>
                <Spacer />
                <HStack spacing="12px">
                    <Button w="100%" variant="outline"
                    fontWeight="500"
                    onClick={onReset}
                    >
                    Cancelar
                    </Button>
                    <Button w="100%" colorScheme="buttons.primary"
                    fontWeight="500"
                    isDisabled={members.length === 0}
                    isLoading={isSending}
                    onClick={onSend}
                    >
                    Enviar
                    </Button>
                </HStack>
            </Flex>
        </Box>
    );
};

export default TeamMembers;