import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryParam, StringParam } from 'use-query-params';

import { PeopleRepository } from '@repositories';

import { FullCenterContainer } from '@common/layouts';
import { Footer, Text, VStack } from '@common/components';
import Header from './components/Header';

const PeopleCongratulations = () =>
{
    const [token] = useQueryParam('token', StringParam);
    const { t } = useTranslation();
    useEffect(() => {
        async function deleteToken () {
            try {
                await PeopleRepository.finishTest(token);
            } catch (error) {

            }
        }
        deleteToken();
    }, [token]);
    return (
        <FullCenterContainer footer={<Footer />}>
            <Header />
            <VStack spacing="38px" maxW="736px" textAlign="center" color="#25201B">
                <Text fontSize="64">
                🚀
                </Text>
                <Text fontSize="30px" fontWeight="500">
                {t('congratulations.title')}
                </Text>
                <Text fontSize="30px" fontWeight="500">
                {t('congratulations.description.part1')}
                </Text>
            </VStack>
        </FullCenterContainer>
    );
}

export default PeopleCongratulations;