import { Box, Flex, Logo, Text, LinkButton } from '@common/components';
import links from '@common/links';

const Footer = ({ onlyLegal }) =>
(
    <Box mb="40px" width="100%">
        <Flex flexDirection="row" maxW="1216px" mx="auto" h="100%">
            <Flex align="center" justify="center">
                <Logo />
            </Flex>
            <Flex flex="1" align="center" justify="center">
                { !onlyLegal && <Box px="12px"><LinkButton to={links.teams}>Equipos</LinkButton></Box> }
                { !onlyLegal && <Box px="12px"><LinkButton to={links.people}>Personas</LinkButton></Box> }
                { !onlyLegal && <Box px="12px"><Text fontSize="14px" fontWeight="500" color="link">/</Text></Box> }
                <Box px="12px"><LinkButton to={links.termsandconditions} isExternal>Términos y condiciones</LinkButton></Box>
                <Box px="12px"><LinkButton to={links.privacypolicy} isExternal>Política de privacidad</LinkButton></Box>
                <Box px="12px"><LinkButton to={links.cookiepolicy} isExternal>Política de cookies</LinkButton></Box>
            </Flex>
            <Flex align="center">
                <Text fontSize="14px" fontWeight="400" color="#98A2B3">© 2024 SmarTest</Text>
            </Flex>
        </Flex>
    </Box>
);

export default Footer;