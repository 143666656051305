import { Modal, ModalOverlay, ModalContent, Center, Text, Icon, Box } from '@common/components';

const ModalUnsavedData = ({ isOpen, onClose, onAddMembers }) =>
(
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent p="24px">
            <Center bg="#F6FEF9" borderRadius="full" boxSize="56px">
                <Icon icon="success" color="#70B77E" size="23px" />
            </Center>
            <Text mt="20px" fontSize="18px" fontWeight="400" color="#222C45">Los cambios se han guardado.</Text>
            <Box mt="20px">
                <Box as="button" bg="transparent"
                fontSize="18px" color="#667085" fontWeight="400"
                _hover={{
                    bg: 'transparent',
                    color: '#222C45'
                }}
                onClick={onAddMembers}
                >
                    <Text as="u">
                    Ya puedes invitar a los miembros del equipo.
                    </Text>
                </Box>
            </Box>
        </ModalContent>
    </Modal>
);

export default ModalUnsavedData;