import { Tr, Td, Avatar, Circle, AvatarGroup, HStack, VStack, Text, Icon, Tooltip, LinkButton } from '@common/components';
import links from '@common/links';

const TeamName = ({ index, name, web, logo, id }) =>
(
    <HStack spacing="12px">
        <Avatar size="sm" src={logo} bg="white" border="1px solid #C4C4C4" />
        <VStack spacing="0px" align="left">
            <LinkButton id={`teams-tablerow-${index}-name`} to={links.team(id)}>
                <Text isTruncated maxW="190px" fontFamily="Inter" fontSize="14px" fontWeight="500" color="#101828">{name}</Text>
            </LinkButton>
            <Text isTruncated maxW="190px" fontFamily="Inter" fontSize="14px" fontWeight="400" color="#667085">{web}</Text>
        </VStack>
    </HStack>
);

const TeamPeople = ({ people }) =>
(
    <AvatarGroup max={3} size="sm" spacing="-8px">
    {people.map((person, index) =>
        <Avatar key={`${person}::${index}`}>
        <Circle
        size="32px"
        border="1px solid #C4C4C4"
        bg="#F9FAFB"
        >
            <Tooltip hasArrow borderRadius="8px" p="12px" placement="top-end" label={person} bg="#222C45" color="white">
                <Text fontSize="16px" fontWeight="500" color="#475467">
                {person?.match(/(\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase()}
                </Text>
            </Tooltip>
        </Circle>
        </Avatar>
    )}
    </AvatarGroup>
);

const TeamStatus = ({ status }) =>
{
    if (status === 'success') return <TeamStatusSuccess />
    if (status === 'warning') return <TeamStatusWarning />
    if (status === 'danger') return <TeamStatusDanger />
    return null;
};

const TeamStatusSuccess = () =>
(
    <HStack spacing="13px">
        <Icon icon="success" size="18px" color="#127826" />
        <VStack spacing="4px" align="left">
            <Text fontFamily="Inter" fontSize="14px" fontWeight="500" color="#127826">Equipo equilibrado</Text>
            <Tooltip hasArrow borderRadius="8px" p="12px" placement="top-end" label="Este es un equipo equilibrado según el análisis realizado a todas las competencias y capacidades de los miembros que lo componen." bg="#222C45" color="white">
                <Text as="u" fontFamily="Inter" fontSize="14px" fontWeight="400" color="#127826">Más información</Text>
            </Tooltip>
        </VStack>
    </HStack>
);

const TeamStatusWarning = () =>
(
    <HStack spacing="13px">
        <Icon icon="warning" size="18px" color="#BE7C01" />
        <VStack spacing="4px" align="left">
            <Text fontFamily="Inter" fontSize="14px" fontWeight="500" color="#BE7C01">Necesita atención</Text>
            <Tooltip hasArrow borderRadius="8px" p="12px" placement="top-end" label="Este equipo requiere atención, porque existen áreas competenciales importantes sin cubrir." bg="#222C45" color="white">
                <Text as="u" fontFamily="Inter" fontSize="14px" fontWeight="400" color="#BE7C01">Más información</Text>
            </Tooltip>
        </VStack>
    </HStack>
);

const TeamStatusDanger = () =>
(
    <HStack spacing="13px">
        <Icon icon="danger" size="18px" color="#8A5DB7" />
        <VStack spacing="4px" align="left">
            <Text fontFamily="Inter" fontSize="14px" fontWeight="500" color="#8A5DB7">Riesgo alto</Text>
            <Tooltip hasArrow borderRadius="8px" p="12px" placement="top-end" label="Este equipo es de alto riesgo, porque uno o más de sus miembros ha puntuado bajo en áreas competenciales importantes y no existe nadie del equipo que la complemente." bg="#222C45" color="white">
                <Text as="u" fontFamily="Inter" fontSize="14px" fontWeight="400" color="#8A5DB7">Más información</Text>
            </Tooltip>
        </VStack>
    </HStack>
);

const TeamTableRow = ({ team, index }) =>
(
    <Tr id={`teams-tablerow-${index}`} bg={index % 2 ? 'white' : '#F9FAFB'}>
        <Td>
            <TeamName index={index} id={team.id} name={team.name} web={team.web} logo={team.logo} />
        </Td>
        <Td>
            <Text isTruncated maxW="300px" fontFamily="Inter" fontSize="14px" fontWeight="400" color="#101828">{team.description}</Text>
        </Td>
        <Td>
            <TeamPeople people={team.persons} />
        </Td>
        <Td>
            <TeamStatus status={team.status.total} />
        </Td>
    </Tr>
);

export default TeamTableRow;