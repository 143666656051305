import { HStack } from '@common/components';
import { ReactComponent as SmartestLogo } from '@assets/smartest_logo.svg';
import { ReactComponent as SmartestText } from '@assets/smartest_text.svg';

const Logo = () =>
(
    <HStack spacing="12px" id="logo">
        <SmartestLogo />
        <SmartestText />
    </HStack>
);

export default Logo;