import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import links from '@common/links';

import { UserRepository, PeopleRepository } from '@repositories';

import { Header, Spinner, Footer } from '@common/components';
import { FullContainer } from '@common/layouts';
import Title from './components/PeopleTitle';
import VCTable from './components/VCPeopleTable';
import SportsTable from './components/SportsPeopleTable';

const People = () =>
{
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [category, setCategory] = useState();
    const [people, setPeople] = useState([]);
    useEffect(() => {
        async function findPeople () {
            try {
                const { category, people } = await PeopleRepository.findAll();
                setCategory(category);
                setPeople(people);
                setIsLoading(false);
            } catch (error) {
                UserRepository.signOut();
                navigate(links.signin);
            }
        }
        findPeople();
    }, [navigate]);

    return (
        <FullContainer
        header={<Header peopleIsActive />}
        footer={<Footer />}
        >
            <Title />
            {
                isLoading 
                ? <Spinner mt="40px" />
                : category === 'VC'
                    ? <VCTable people={people} />
                    : <SportsTable people={people} />
            }
        </FullContainer>
    );
}

export default People;