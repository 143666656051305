import UIBackgroundContainer from '@common/layouts/BackgroundContainer';
import UILoadingContainer from '@common/layouts/LoadingContainer';
import UIFullContainer from '@common/layouts/FullContainer';
import UIFullCenterContainer from '@common/layouts/FullCenterContainer';
import UIVerticalContainer from './VerticalContainer';
import {
    Container as ChakraContainer,
    Grid as ChakraGrid,
    GridItem as ChakraGridItem
} from '@chakra-ui/react';

// Chakra layouts
export const Container = ChakraContainer;
export const Grid = ChakraGrid;
export const GridItem = ChakraGridItem;
// Custom UI layouts
export const BackgroundContainer = UIBackgroundContainer;
export const LoadingContainer = UILoadingContainer;
export const FullContainer = UIFullContainer;
export const FullCenterContainer = UIFullCenterContainer;
export const VerticalContainer = UIVerticalContainer;