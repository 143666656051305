import { Center, Spinner } from '@common/components';
import { Container } from '@common/layouts';

const LoadingContainer = () =>
(
    <Container maxW="1216px" minH="100vh" centerContent>
        <Center minH="100vh" pb="70px">
            <Spinner color="gray.400" />
        </Center>
    </Container>
);

export default LoadingContainer;