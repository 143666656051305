import { useRef } from 'react';
import { Text, Circle, Tooltip } from '@common/components';

const Dataset = ({ data }) =>
{
    const items = data.map((set) => {
        const isGroup = set.label?.includes('+');
        if (!set.label) return null;
        if (set.x <= 4.5) set.x = 4.5;
        if (set.x >= 95.5) set.x = 95.5;
        if (set.y <= 7) set.y = 7;
        if (set.y >= 93) set.y = 93;
        if (!isGroup) set.label = set.label?.match(/(\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase();
        return (<Point key={set.email} x={set.x} y={set.y} label={set.label} color={isGroup ? '#E2E8F0' : '#F9FAFB'} tooltip={set.tooltip} />);
    });
    return items;
};

const Point = ({ x, y, color, label, tooltip }) =>
{
    const circleRef = useRef(null);
    return (
        <Tooltip hasArrow borderRadius="8px" p="12px" placement="top" label={tooltip} bg="#222C45" color="white" offset=""
            onOpen={() => { circleRef.current.style.zIndex = 100; }}
            onClose={() => { circleRef.current.style.zIndex = 1; }}>
            <Circle
            ref={circleRef}
            style={{ boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', position: 'absolute', top: `${100 - y}%`, left: `${x}%` }} 
            ml="-20px" mt="-20px"
            size="40px"
            bg={color}
            >
                <Text fontSize="16px" fontWeight="500" color="#475467">
                {label}
                </Text>
            </Circle>
        </Tooltip>
    );
};

export default Dataset;