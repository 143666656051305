import { Children, isValidElement } from 'react';

export const cleanChildren = children => Children.toArray(children).filter(child => isValidElement(child));
export const resolveChildren = (children, reducer) => cleanChildren(children).reduce(reducer, {});

export const mergeRefs = (...refs) =>
{
    const filteredRefs = refs.filter(Boolean);
    if (!filteredRefs.length) return null;
    if (filteredRefs.length === 0) return filteredRefs[0];
    return inst => {
        for (const ref of filteredRefs) {
            if (typeof ref === 'function') ref(inst);
            else if (ref) ref.current = inst;
        }
    };
};