import links from '@common/links';

import { VStack, HStack, LinkButton, Icon, Box, Text, Link, Button } from '@common/components';
import { FullCenterContainer } from '@common/layouts';
import ResetHeader from './ResetHeader';

const ResetAccount = ({ email, onReset, onSet }) =>
(
    <FullCenterContainer>
        <VStack spacing="32px" w="100%" maxW="410px">
            <ResetHeader email={email} />
            <Button w="100%" colorScheme="buttons.primary" mt="24px"
            fontWeight="500"
            onClick={onSet}
            >
            Nueva contraseña
            </Button>
            <Box my="32px" w="100%">
                <Text fontFamily="Inter" fontSize="14px" fontWeight="400" color="link">
                    ¿No has recibido el correo electrónico?{' '}
                    <Link fontFamily="Inter"
                    fontSize="14px"
                    fontWeight="500"
                    color="link" onClick={onReset}>
                    Haz clic para reenviar
                    </Link>
                </Text>
            </Box>
            <HStack w="100%" spacing="12px" justify="center">
                <Icon icon="arrow-left" color="#667085" size="20px" />
                <LinkButton to={links.signin}>
                Volver al inicio de sesión
                </LinkButton>
            </HStack>
        </VStack>
    </FullCenterContainer>
);

export default ResetAccount;