import links from '@common/links';

import { VStack, HStack, LinkButton, Icon } from '@common/components';
import { FullCenterContainer } from '@common/layouts';
import RecoverHeader from './RecoverHeader';
import RecoverForm from './RecoverForm';

const RecoverAccount = ({ code, onChangeCode, password, onChangePassword, confirm, onChangeConfirm, isLoading, onRecover }) =>
(
    <FullCenterContainer>
        <VStack spacing="32px" w="100%" maxW="360px">
            <RecoverHeader />
            <RecoverForm
            code={code} onChangeCode={onChangeCode}
            password={password} onChangePassword={onChangePassword}
            confirm={confirm} onChangeConfirm={onChangeConfirm}
            isRecovering={isLoading}
            onRecover={onRecover}
            />
            <HStack w="100%" spacing="12px" justify="center">
                <Icon icon="arrow-left" color="#667085" size="20px" />
                <LinkButton to={links.signin}>
                Volver al inicio de sesión
                </LinkButton>
            </HStack>
        </VStack>
    </FullCenterContainer>
);

export default RecoverAccount;