import { Box, Flex, Spacer, Logo } from '@common/components';

const Header = () =>
(
    <Box
    bg="white"
    pos="fixed" top={0} zIndex={1000}
    borderBottom="1px"
    borderBottomColor="others.divider"
    w="100%" h="73px">
        <Flex px="2.5em" flexDirection="row" maxW="1480px" mx="auto" h="100%">
            <Spacer />
            <Flex align="center" justify="center">
                <Logo />
            </Flex>
            <Spacer />
        </Flex>
    </Box>
);

export default Header;