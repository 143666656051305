import { Link } from '@common/components';
import { Link as ReactRouter } from 'react-router-dom';

const ExtenalLink = ({ id, children, to }) =>
(
    <Link
    id={id}
    fontFamily="Inter"
    fontSize="14px"
    fontWeight="500"
    color="link"
    href={to}
    isExternal
    >
    {children}
    </Link> 
);

const LocalLink = ({id, children, to }) =>
(
    <Link as={ReactRouter}
    id={id}
    fontFamily="Inter"
    fontSize="14px"
    fontWeight="500"
    color="link"
    to={to}
    >
    {children}
    </Link>
);

const LinkButton = ({ id, children, to, isExternal }) =>
{
    if (isExternal) return <ExtenalLink id={id} to={to}>{children}</ExtenalLink>
    return <LocalLink id={id} to={to}>{children}</LocalLink>
}

export default LinkButton;
