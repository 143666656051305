import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { FullCenterContainer } from '@common/layouts';
import { Flex, Logo, Box, Heading, HeadingText, VStack, Spacer, HStack, LinkButton, Footer } from '@common/components';
import links from '@common/links';

const NotFound = () =>
{
    const items = useMemo(() => ['(o_o)', '(>_<)', '(˚Δ˚)', '(^Д^)', '(^-^*)', '(^_^)', '(·_·)'], []);
    const face = useMemo(() => items[Math.floor(Math.random() * items.length)], [items])
    return (
        <FullCenterContainer footer={<Footer />}>
            <Box
            bg="white"
            pos="fixed" top={0} zIndex={1000}
            borderBottom="1px"
            borderBottomColor="others.divider"
            w="100%" h="73px"
            >
                <Flex px="2.5em" flexDirection="row" maxW="1480px" mx="auto" h="100%">
                    <Spacer />
                    <Flex align="center" justify="center">
                        <Link to={links.home}>
                            <Logo />
                        </Link>
                    </Flex>
                    <Spacer />
                </Flex>
            </Box>
            <Box w="100%" textAlign="center">
                <VStack spacing="20px">
                    <Heading size="4xl">
                    {face}
                    </Heading>
                    <HeadingText size="lg">
                        Oops! Página no encontrada.
                    </HeadingText>
                    <HStack w="100%" spacing="12px" justify="center">
                        <LinkButton to={links.signin}>
                        Volver al inicio de sesión
                        </LinkButton>
                    </HStack>
                </VStack>
            </Box>
        </FullCenterContainer>
    );
}

export default NotFound;