import { Box, HeadingText, SupportingText } from '@common/components';

const TeamsTitle = () =>
(
    <Box mt="73px" id="teams-title">
        <HeadingText>
        ¡Hola!
        </HeadingText>
        <SupportingText>
        Visualiza, analiza y decide.
        </SupportingText>
    </Box>
);

export default TeamsTitle;