import { VStack, HeadingText, SupportingText } from '@common/components';

const SignInHeader = () =>
(
    <VStack spacing="12px">
        <HeadingText>Accede a tu cuenta</HeadingText>
        <SupportingText>Por favor, introduce tus datos.</SupportingText>
    </VStack>
);

export default SignInHeader;