import { useTranslation } from 'react-i18next';
import { Box, Tab, FormInput, Flex, Spacer, Button, Select } from '@common/components';
import Title from './Title';
import Section from './Section';
import Panel from './Panel';

const PersonalData = ({ name, onChangeName, age, onChangeAge, gender, onChangeGender, education, onChangeEducation, isSaving, onSave }) =>
{
    const { t } = useTranslation();
    return (
        <Box w="100%">
            <Title />
            <Box mt="25px" borderBottom="1px solid #EAECF0">
                <Tab id="1" isSelected>
                {t('invitation.form.tab')}
                </Tab>
            </Box>
            <Box px="32px">
                <Section title={t('invitation.form.description.title')} subtitle={t('invitation.form.description.subtitle')} />
                <Box h="1px" bg="#EAECF0" />
                <Panel info={t('invitation.form.description.field1')} form={
                    <FormInput maxW="512px"
                    placeholder={t('invitation.form.description.field1')}
                    value={name} onChange={(event) => { onChangeName(event.target.value); }}
                    isDisabled={isSaving}
                    focusBorderColor="#70B37D" color="#667085" bg="white"  />
                } />
                <Box h="1px" bg="#EAECF0" />
                <Panel info={t('invitation.form.description.field2')} form={
                    <FormInput maxW="512px"
                    placeholder={t('invitation.form.description.field2')}
                    value={age} onChange={(event) => { onChangeAge(event.target.value); }}
                    focusBorderColor="#70B37D" color="#667085" bg="white"  />
                } />
                <Box h="1px" bg="#EAECF0" />
                <Panel info={t('invitation.form.description.field3')} form={
                    <Select maxW="512px" focusBorderColor="#70B37D" color="#667085" bg="white"
                    placeholder={t('invitation.form.description.field3')} onChange={(event) => { onChangeGender(event.target.value); }}>
                        <option value="hombre">{t('invitation.form.values.gender.male')}</option>
                        <option value="mujer">{t('invitation.form.values.gender.female')}</option>
                        <option value="nobinario">{t('invitation.form.values.gender.nonbinary')}</option>
                    </Select>
                } />
                <Box h="1px" bg="#EAECF0" />
                <Panel info={t('invitation.form.description.field4')} form={
                    <Select maxW="512px" focusBorderColor="#70B37D" color="#667085" bg="white"
                    placeholder={t('invitation.form.description.field4')} onChange={(event) => { onChangeEducation(event.target.value); }}>
                        <option value="estudiosprimarios">{t('invitation.form.values.education.primaryschool')}</option>
                        <option value="estudiomedios">{t('invitation.form.values.education.highschool')}</option>
                        <option value="licenciados">{t('invitation.form.values.education.graduates')}</option>
                        <option value="doctoreseingenieros">{t('invitation.form.values.education.doctorsengineers')}</option>
                    </Select>
                } />
                <Box h="1px" bg="#EAECF0" />
                <Flex mt="24px">
                    <Spacer />
                    <Button colorScheme="buttons.secondary"
                    fontWeight="500"
                    isDisabled={name.length === 0 || age.length === 0 || gender.length === 0 || education.length === 0}
                    isLoading={isSaving}
                    onClick={onSave}
                    >
                    {t('invitation.form.action.save')}
                    </Button>
                </Flex>
            </Box>
        </Box>
    );
};

export default PersonalData;