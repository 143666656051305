import { Box, InputForm, Button } from '@common/components';

const ForgotForm = ({ email, onChangeEmail, onReset, isReseting }) =>
(
    <Box w="100%" fontSize="16px">
        <InputForm id="email"
        type="email"
        text="Correo electrónico"
        placeholder="Introduce tu correo electrónico"
        value={email}
        onChange={onChangeEmail}
        />
        <Button w="100%" colorScheme="buttons.primary" mt="24px"
        fontWeight="500"
        isLoading={isReseting}
        onClick={onReset}
        >
        Restablecer contraseña
        </Button>
    </Box>
);

export default ForgotForm;