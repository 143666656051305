import request from 'axios';

const API = `${process.env.REACT_APP_API_BASE}/api/v1`;
const requestor = request.create({ baseURL: API });

const HeadersAdapter = (session) =>
{
    return { 
        ...session && { 'Authorization': session.accessToken },
        ...session && { 'Session': [
            `accessToken::${session.accessToken}`,
            `idToken::${session.idToken}`,
            `refreshToken::${session.refreshToken}`
        ]}
    }
};

const NetwordAdapter =
{
    get (url, { session }) { return requestor({ url, headers: HeadersAdapter(session) }); },
    post (url, { session, body }) { return requestor.post(url, body, { headers: HeadersAdapter(session) }); },
    patch (url, { session, body }) { return requestor.patch(url, body, { headers: HeadersAdapter(session) }); },
    delete (url, { session, data }) { return requestor.delete(url, { data, headers: HeadersAdapter(session) }); }
};

export const Api =
{
    // Auth
    signUp ({ token, password }) {
        return NetwordAdapter.post('/client/signup', { body: { token, password }});
    },
    signIn ({ email, password }) {
        return NetwordAdapter.post('/client/signin', { body: { email, password }});
    },
    verifyAccount ({ email, code }) {
        return NetwordAdapter.post('/client/verify', { body: { email, code }});
    },
    resetPassword ({ body }) {
        return NetwordAdapter.post('/client/reset', { body });
    },
    setPassword ({ body }) {
        return NetwordAdapter.post('/client/recover', { body });
    },
    // Teams
    fetchTeams ({ client, session }) {
        return NetwordAdapter.get(`/client/${client}/team`, { session });
    },
    fetchTeam ({ client, session, team }) {
        return NetwordAdapter.get(`/client/${client}/team/${team}`, { session });
    },
    addTeam ({ session, body }) {
        return NetwordAdapter.post('/team', { session, body });
    },
    updateTeam ({ session, team, body }) {
        return NetwordAdapter.patch(`/team/${team}`, { session, body });
    },
    acceptTeam ({ body }) {
        return NetwordAdapter.post('/team/accept', { body });
    },
    uploadTeamLogo ({ session, file }) {
        const formData = new FormData();
        formData.append('avatar', file);
        return requestor.post('/team/upload/avatar', formData, {
            headers: {
                ...HeadersAdapter(session),
                ...{ 'Content-Type': 'multipart/form-data' }
            }
        });
    },
    // People
    fetchPeople ({ client, session }) {
        return NetwordAdapter.get(`/client/${client}/person`, { session });
    },
    sendPeopleInvitations ({ session, body }) {
        return NetwordAdapter.post('/person', { session, body });
    },
    claimPeopleInvitation ({ body }) {
        return NetwordAdapter.post('/person/invitation', { body })
    },
    updatePersonWithToken ({ person, body }) {
        return NetwordAdapter.patch(`/person/${person}`, { body });
    },
    copyPersonWithToken ({ person, body }) {
        return NetwordAdapter.post(`/person/${person}/copy`, { body });
    },
    deletePeopleInvitation ({ data }) {
        return NetwordAdapter.delete('/person/invitation', { data })
    },
    // Client
    fetchClient ({ session, client }) {
        return NetwordAdapter.get(`/client/account/${client}`, { session });
    },
    updatePassword ({ session, client, body }) {
        return NetwordAdapter.post(`/client/account/${client}/password`, { session, body });
    }
};