import { useState, useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';
import { useQueryParam, StringParam } from 'use-query-params';
import links from '@common/links';
import { trim, compact } from 'lodash';

import { UserRepository, TeamRepository, PeopleRepository } from '@repositories';

import { Header, Footer, Box, HeadingText, ButtonGroup, Tab, Spinner } from '@common/components';
import { FullContainer } from '@common/layouts';
import TeamTitle from './components/TeamTitle';
import TeamDescription from './components/TeamDescription';
import TeamMembers from './components/TeamMembers';
import ModalSavedMembers from './components/ModalSavedData';

const EditTeam = () =>
{
    const { teamid } = useParams();
    const [tab] = useQueryParam('tab', StringParam);
    const navigate = useNavigate();
    const toast = useToast();
    const [isLoading, setIsLoading] = useState(true);
    const [isUpdating, setIsUpdating] = useState(false);
    const [team, setTeam] = useState();
    const [members, setMembers] = useState('');
    const [invitations, setInvitations] = useState([]);
    const onChangeMembers = useCallback((text) => { setMembers(text); }, []);
    const [name, setName] = useState('');
    const onChangeName = useCallback((text) => { setName(text); }, []);
    const [web, setWeb] = useState('');
    const onChangeWeb = useCallback((text) => { setWeb(text); }, []);
    const [description, setDescription] = useState('');
    const onChangeDescription = useCallback((text) => { setDescription(text); }, []);
    const [twitter, setTwitter] = useState('');
    const onChangeTwitter = useCallback((text) => { setTwitter(text); }, []);
    const [instagram, setInstagram] = useState('');
    const onChangeInstagram = useCallback((text) => { setInstagram(text); }, []);
    const [linkedin, setLinkedin] = useState('');
    const onChangeLinkedin = useCallback((text) => { setLinkedin(text); }, []);
    const [logo, setLogo] = useState('');
    const onChangeLogo = useCallback((logo) => { setLogo(logo); }, []);
    const [isOpenModalSavedMembers, setOpenModalSavedMembers] = useState();
    const handleOnCloseModalSavedMembers = useCallback(() => {
        setOpenModalSavedMembers(false);
    }, []);

    const [tabSelected, setTabSelected] = useState(tab || "1");
    const handleTabClick = useCallback((tab) => {
        setTabSelected(tab);
    }, []);

    const [locale, setLocale] = useState();
    const handleChangeLocale = useCallback(async (lang) => {
        setLocale(lang);
        await TeamRepository.update(team.id, { locale: lang });
    }, [team]);

    useEffect(() => {
        async function findTeam () {
            try {
                const team = await TeamRepository.findOne(teamid) 
                setTeam(team);
                setName(team.name);
                setWeb(team.web?.replace('https://', '') || '');
                setDescription(team.description || '');
                setTwitter(team.socialmedia.twitter?.replace('https://twitter.com/', '') || '');
                setInstagram(team.socialmedia.instagram?.replace('https://instagram.com/', '') || '');
                setLinkedin(team.socialmedia.linkedin?.replace('https://linkedin.com/company/', '') || '');
                setLogo(team.logo);
                setLocale(team.config?.locale || 'es');
                setIsLoading(false);
            } catch (error) {
                if (error.response?.status === 401) {
                    UserRepository.signOut();
                    navigate(links.signin);
                }
            }
        }
        findTeam();
    }, [navigate, teamid]);
    const handleUpdateTeam = useCallback(async () => {
        setIsUpdating(true);
        try {
            setTeam(await TeamRepository.update(team.id, {
                name, 
                ...web.length > 0 && { web: `https://${web}` }, 
                ...description.length > 0 && { description }, 
                ...twitter.length > 0 && { twitter: `https://twitter.com/${twitter}` }, 
                ...instagram.length > 0 && { instagram: `https://instagram.com/${instagram}` }, 
                ...linkedin.length > 0 && { linkedin: `https://linkedin.com/company/${linkedin}` },
                ...logo.length > 0 && { logo }
            }));
        } catch (error) {
            if (error.response?.status === 401) {
                UserRepository.signOut();
                navigate(links.signin);
            } else toast({
                title: error.response?.data?.error?.message || 'Error editando el equipo.',
                status: 'error',
                duration: 3000,
                isClosable: true,
                position: 'top'
            });
        }
        setIsUpdating(false);
    }, [team, name, web, description, twitter, instagram, linkedin, logo, navigate, toast]);
    const handleResetTeam = useCallback(() => {
        setName(team.name);
        setWeb(team.web?.replace('https://', '') || '');
        setDescription(team.description || '');
        setTwitter(team.socialmedia.twitter?.replace('https://twitter.com/', '') || '');
        setInstagram(team.socialmedia.instagram?.replace('https://instagram.com/', '') || '');
        setLinkedin(team.socialmedia.linkedin?.replace('https://linkedin.com/company/', '') || '');
        setLogo(team.logo || '');
    }, [team]);
    const handleSendInvitations = useCallback(async () => {
        setIsUpdating(true);
        try {
            const newInvitations = compact(members.split('\n').map((email) => trim(email)));
            await PeopleRepository.sendInvitations(team.id, newInvitations);
            setMembers('');
            setInvitations(invitations.concat(newInvitations));
            setOpenModalSavedMembers(true);
        } catch (error) {
            if (error.response?.status === 401) {
                UserRepository.signOut();
                navigate(links.signin);
            } else toast({
                title: 'Comprueba que los emails son correctos.',
                status: 'error',
                duration: 3000,
                isClosable: true,
                position: 'top'
            });
        }
        setIsUpdating(false);
    }, [team, members, invitations, toast, navigate]);
    const handleResetInvitations = useCallback(() => {
        setMembers('');
    }, []);
    const handleViewTeam = useCallback(() => {
        navigate(links.team(teamid));
    }, [navigate, teamid]);

    return (
        <FullContainer
        header={<Header />}
        footer={<Footer />}
        >
        {
            isLoading 
            ? <Spinner mt="73px" />
            : <>
                <TeamTitle team={team.name} onViewTeam={handleViewTeam} />
                <Box mt="18px" p="32px" bgColor="#F9FAFB" borderRadius="8px" border="1px solid #F9F9F9">
                    <HeadingText>
                    Datos del equipo
                    </HeadingText>
                    <Box mt="25px" mb="32px">
                        <ButtonGroup gap="24px">
                            <Tab id="1" isSelected={tabSelected === "1"} onChange={handleTabClick}>
                            Descripción general
                            </Tab>
                            <Tab id="2" isSelected={tabSelected === "2"} onChange={handleTabClick}>
                            Miembros del equipo
                            </Tab>
                        </ButtonGroup>
                    </Box>
                    {tabSelected === "1"
                    ? <TeamDescription
                        isUpdating={isUpdating}
                        name={name} onChangeName={onChangeName}
                        web={web} onChangeWeb={onChangeWeb}
                        description={description} onChangeDescription={onChangeDescription}
                        twitter={twitter} onChangeTwitter={onChangeTwitter}
                        instagram={instagram} onChangeInstagram={onChangeInstagram}
                        linkedin={linkedin} onChangeLinkedin={onChangeLinkedin}
                        logo={logo} onChangeLogo={onChangeLogo}
                        onUpdate={handleUpdateTeam}
                        onReset={handleResetTeam}
                    />
                    : <TeamMembers
                        isSending={isUpdating}
                        members={members} onChangeMembers={onChangeMembers}
                        invitations={invitations}
                        onSend={handleSendInvitations}
                        onReset={handleResetInvitations}
                        locale={locale} onChangeLocale={handleChangeLocale}
                    />
                    }
                </Box>
            </>
        }
        <ModalSavedMembers isOpen={isOpenModalSavedMembers}
        onClose={handleOnCloseModalSavedMembers}
        onAddMembers={handleSendInvitations}
        />
        </FullContainer>
    );
};

export default EditTeam;