import { VStack, HeadingText, SupportingText, Icon, Center } from '@common/components';

const ForgotHeader = () =>
(
    <VStack spacing="12px" textAlign="center">
        <Center bg="#F7FAFC" borderRadius="full" boxSize="56px">
            <Icon icon="lock" color="#5A81A4" size="23px" />
        </Center>
        <HeadingText>Verificar tu cuenta</HeadingText>
        <SupportingText>Para empezar a explorar SmarTest, por favor introduce el código que recibiste en tu correo electrónico.</SupportingText>
    </VStack>
);

export default ForgotHeader;