import { isNumber, toNumber, isNaN } from 'lodash';
import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryParam, StringParam } from 'use-query-params';
import { useToast } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import links from '@common/links';

import { PeopleRepository, TeamRepository } from '@repositories';

import { Layout } from './components/Layout';
import TermsData from './components/TermsData';
import PersonalData from './components/PersonalData';

const InvitePeople = () =>
{
    const toast = useToast();
    const navigate = useNavigate();
    const { i18n } = useTranslation();
    const [token] = useQueryParam('token', StringParam);
    const [isTokenInvalid, setIsTokenInvalid] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [state, setState] = useState('termsdata');
    const [invitation, setInvitation] = useState();
    const [isAccepting, setIsAccepting] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [name, setName] = useState('');
    const onChangeName = useCallback((value) => setName(value), []);
    const [age, setAge] = useState('');
    const onChangeAge = useCallback((value) => {
        if (value.length === 0) return setAge('');
        const number = toNumber(value);
        if (isNumber(number) && !isNaN(number)) setAge(number);
    }, []);
    const [gender, setGender] = useState('');
    const onChangeGender = useCallback((value) => setGender(value), []);
    const [education, setEducation] = useState('');
    const onChangeEducation = useCallback((value) => setEducation(value), []);

    useEffect(() => {
        async function fetchToken () {
            try {
                setInvitation(await PeopleRepository.fetchInvitation(token));
            } catch (error) {
                setIsTokenInvalid(true);
                toast({
                    title: 'Token inválido.',
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                    position: 'top'
                });
            }
            setIsLoading(false);
        }
        fetchToken();
    }, [token, toast]);
    const handleAcceptTerms = useCallback(async () => {
        setIsAccepting(true);
        try {
            await TeamRepository.accept({ token, person: invitation.person, team: invitation.team });
            if (invitation.state !== 'filled') setState('personaldata');
            else navigate(links.congratulations);
        } catch (error) {
            
        }
        setIsAccepting(false);
    }, [invitation, token, navigate]);
    const handleSavePersonalData = useCallback(async () => {
        setIsSaving(true);
        try {
            await PeopleRepository.update({ token, personid: invitation.person, name, age, gender, education });
            const locale = i18n.language;
            window.location.href = links.vipscan({ token, locale, personId: invitation.person, age, gender, education });
        } catch (error) {
            setIsSaving(false);
        }
    }, [name, age, gender, education, invitation, token, i18n]);
    return (
        <Layout isLoading={isLoading}>
            <Layout.Content>
            {state === 'termsdata'
            ? <TermsData
                client={invitation && invitation.client}
                logo={invitation && invitation.logo}
                isFilled={invitation && invitation.state === 'filled'}
                isDisabled={!(token && !isTokenInvalid)}
                isAccepting={isAccepting} onAccept={handleAcceptTerms} />
            : <PersonalData
                logo={invitation && invitation.logo}
                name={name} onChangeName={onChangeName}
                age={age} onChangeAge={onChangeAge}
                gender={gender} onChangeGender={onChangeGender}
                education={education} onChangeEducation={onChangeEducation}
                isSaving={isSaving} onSave={handleSavePersonalData} />
            }
            </Layout.Content>
        </Layout>
    );
}

export default InvitePeople;