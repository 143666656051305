import { Box, InputForm, Button, VStack, Text } from '@common/components';

const Form = ({ code, onChangeCode, password, onChangePassword, confirm, onChangeConfirm, isRecovering, onRecover }) =>
(
    <Box w="100%" fontSize="16px">
        <VStack spacing="20px" w="100%">
            <InputForm id="code"
            type="text"
            text="Código de verificación"
            placeholder="Introduce tu código de verificación"
            value={code}
            onChange={onChangeCode}
            />
            <Box>
                <InputForm id="password"
                type="password"
                text="Contraseña"
                placeholder="Introduce tu contraseña"
                value={password}
                onChange={onChangePassword}
                />
                <Text maxW="512px" fontSize="14px" color="#667085" mt="6px">
                    Tu nueva contraseña debe tener más de 8 caracteres, al menos 1 número, al menos 1 caracter especial, al menos 1 mayúscula y al menos 1 minúscula.
                </Text>
            </Box>
            <InputForm id="confirm"
            type="password"
            text="Confirmar contraseña"
            placeholder="Confirma tu contraseña"
            value={confirm}
            onChange={onChangeConfirm}
            />
        </VStack>
        <Button w="100%" colorScheme="buttons.primary" mt="24px"
        fontWeight="500"
        isDisabled={code.length === 0 || password.length === 0 || password !== confirm}
        isLoading={isRecovering}
        onClick={onRecover}
        >
        Restablecer contraseña
        </Button>
    </Box>
);

export default Form;