import { Box, Flex, Text } from '@common/components';

const Panel = ({ info, form }) =>
(
    <Box w="100%" mt="25px" mb="20px">
        <Flex>
            <Box w="280px" >
                <Text fontSize="14px" fontWeight="500" color="#344054">{info}</Text>
            </Box>
            <Box w="32px" />
            <Box flex="1">
            {form}
            </Box>
        </Flex>
    </Box>
);

export default Panel;