import { Button, HStack, Icon, Text, Menu, MenuButton, MenuItem, MenuList } from '@common/components';
import { ReactComponent as ESLang } from '@assets/es.svg';
import { ReactComponent as ENLang } from '@assets/en.svg';

const Language = ({ value, onChange }) =>
{
    value = value.includes('-') ? value.split('-')[0] : value;
    return (
        
        <Menu>
            <MenuButton as={Button} rightIcon={<Icon icon="chevron-down" />}
            maxW="180px" border="1px solid #D0D5DD" bg="white">
            {value === 'es'
            ? <HStack><ESLang /><Text fontWeight="400">Español</Text></HStack>
            : <HStack><ENLang /><Text fontWeight="400">English</Text></HStack>
            }
            </MenuButton>
            <MenuList>
                <MenuItem onClick={() => onChange('es')}>Español</MenuItem>
                <MenuItem onClick={() => onChange('en')}>English</MenuItem>
            </MenuList>
        </Menu>
    );
};

export default Language;