import { Tr, Td, Avatar, Circle, HStack, VStack, Text, Progress, LinkButton } from '@common/components';
import links from '@common/links';

const Person = ({ name, email }) =>
(
    <HStack spacing="12px">
        {!name
            ? <Avatar size="sm" name={name} />
            : <Circle
            size="32px"
            border="1px solid #C4C4C4"
            bg="#F9FAFB"
            >
                <Text fontSize="16px" fontWeight="500" color="#475467">
                {name?.match(/(\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase()}
                </Text>
            </Circle>
        }
        <VStack spacing="0px" align="left">
            <Text isTruncated maxW="190px" fontFamily="Inter" fontSize="14px" fontWeight="500" color="#101828">{name}</Text>
            <Text isTruncated maxW="190px" fontFamily="Inter" fontSize="14px" fontWeight="400" color="#667085">{email}</Text>
        </VStack>
    </HStack>
);

const Team = ({ name, logo, web, id }) =>
(
    <HStack spacing="12px">
        <Avatar size="sm" src={logo} bg="white" border="1px solid #C4C4C4" />
        <VStack spacing="0px" align="left">
            <LinkButton to={links.team(id)}>
                <Text isTruncated maxW="190px" fontFamily="Inter" fontSize="14px" fontWeight="500" color="#101828">{name}</Text>
            </LinkButton>
            <Text isTruncated maxW="190px" fontFamily="Inter" fontSize="14px" fontWeight="400" color="#667085">{web}</Text>
        </VStack>
    </HStack>
);

const Competence = ({ score, color = 'yellow', keyed = 'plus' }) =>
{
    if (score === undefined) return null;
    if (score > 70 && keyed === 'minus') color = 'red';
    if (score <= 30 && keyed === 'minus') color = 'green';
    if (score > 70 && keyed === 'plus') color = 'green';
    if (score <= 30 && keyed === 'plus') color = 'red';
    return (
        <HStack spacing="12px">
            <Progress size="sm" w="60px" value={score} colorScheme={color} borderRadius="4px" />
            <Text fontFamily="Inter" fontSize="14px" fontWeight="500" color="#344054">{`${~~score}%`}</Text>
        </HStack>
    );
};

const PendingStatus = () =>
(
    <Td colSpan="4">
        <Text as="i" fontFamily="Inter" fontSize="14px" fontWeight="500" color="#667085">
        Esta persona aún no ha completado el test
        </Text>
    </Td>
);

const NotAcceptedStatus = () =>
(
    <Td colSpan="4">
        <HStack spacing="4px">
            <Text as="i" fontFamily="Inter" fontSize="14px" fontWeight="500" color="#8A5DB7">
            Esta persona aún
            </Text>
            <Text as="i" style={{ textDecoration: 'underline' }} fontFamily="Inter" fontSize="14px" fontWeight="500" color="#8A5DB7">
            no ha aceptado
            </Text>
            <Text as="i" fontFamily="Inter" fontSize="14px" fontWeight="500" color="#8A5DB7">
            la invitación
            </Text>
        </HStack>
    </Td>
);

const TableRow = ({ person, index }) =>
(
    <Tr bg={index % 2 ? 'white' : '#F9FAFB'}>
        <Td>
            <Person name={person.name} email={person.email} />
        </Td>
        {
            person.status === 'pending'
            ? <PendingStatus />
            : person.status === 'filled' && !person.competences
            ? <NotAcceptedStatus />
            : <>
            <Td>
                <Competence score={person.competences?.troublesome} keyed="minus" />
            </Td>
            <Td>
                <Competence score={person.competences?.unstable} keyed="minus" />
            </Td>
            <Td>
                <Competence score={person.competences?.leadership} />
            </Td>
            <Td>
                <Competence score={person.competences?.teamwork} />
            </Td>
            </>
        }
        <Td>
            <Team id={person.team.id} name={person.team.name} logo={person.team.logo} web={person.team.web} />
        </Td>
    </Tr>
);

export default TableRow;
