import { useCallback, useEffect, useState } from 'react';
import { useAuth } from '@common/providers/Auth';
import { useNavigate } from 'react-router-dom';
import { useQueryParam, StringParam } from 'use-query-params';
import links from '@common/links';

import { UserRepository } from '@repositories';

import { VStack, Logo } from '@common/components';
import { FullCenterContainer } from '@common/layouts';
import SignInHeader from './components/SignInHeader';
import SignInForm from './components/SignInForm';

const SignIn = () =>
{
    const { onSignIn } = useAuth();
    const navigate = useNavigate();
    const [emailParam] = useQueryParam('email', StringParam);
    const [isLoading, setIsLoading] = useState(true);
    const [isAuthenticating, setIsAuthenticating] = useState(false);
    const [email, setEmail] = useState(emailParam || '');
    const handleChangeEmail = (value) => setEmail(value);
    const [password, setPassword] = useState('');
    const handleChangePassword = (value) => setPassword(value);
    const handleSignIn = useCallback(async () => {
        if (email.length === 0 || password.length === 0) return;
        setIsAuthenticating(true);
        onSignIn({ email, password });
    }, [email, password, onSignIn]);
    useEffect(() => {
        if (UserRepository.getSession()) return navigate(links.teams);
        setIsLoading(false);
    }, [navigate]);

    if (isLoading) return null;
    return (
        <FullCenterContainer>
            <VStack spacing="32px" w="100%" maxW="360px">
                <Logo />
                <SignInHeader />
                <SignInForm
                email={email} onChangeEmail={handleChangeEmail}
                password={password} onChangePassword={handleChangePassword}
                isAuthenticating={isAuthenticating}
                onSignIn={handleSignIn}
                />
            </VStack>
        </FullCenterContainer>
    );
}

export default SignIn;