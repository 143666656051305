const LocalStorageAdapter =
{
    get (key) { return localStorage.getItem(key); },
    getAll () { return {...localStorage}; },
    set (key, value) { localStorage.setItem(key, value); },
    remove (key) { localStorage.removeItem(key); },
    clear () { localStorage.clear(); }
};

export const Storage =
{
    // JWT Token
    setSession ({ email, client, session }) {
        LocalStorageAdapter.set('session', JSON.stringify({ email, client, session }));
    },
    getClientId () {
        if (!LocalStorageAdapter.get('session')) return null;
        return JSON.parse(LocalStorageAdapter.get('session')).client;
    },
    deleteSession () {
        LocalStorageAdapter.remove('session');
    },
    getSession () {
        if (!LocalStorageAdapter.get('session')) return null;
        return JSON.parse(LocalStorageAdapter.get('session')).session;
    }
};