import { Api, Storage } from '@services';
import { Team } from '@entities';

export const TeamRepository =
{
    findAll: async () => {
        const session = Storage.getSession();
        const client = Storage.getClientId();
        const response = await Api.fetchTeams({ client, session });
        return response.data.content.map((team) => Team.fromJson(team));
    },
    findOne: async (team) => {
        const session = Storage.getSession();
        const client = Storage.getClientId();
        const response = await Api.fetchTeam({ client, session, team });
        return Team.fromJson(response.data.content);
    },
    add: async (data) => {
        const session = Storage.getSession();
        const clientid = Storage.getClientId();
        const response = await Api.addTeam({ session, body: { clientid, ...data }});
        return Team.fromJson(response.data.content);
    },
    update: async (team, data) => {
        const session = Storage.getSession();
        const clientid = Storage.getClientId();
        const response = await Api.updateTeam({ session, team, body: { clientid, ...data }});
        return Team.fromJson(response.data.content);
    },
    accept: async ({ token, team, person }) => {
        return await Api.acceptTeam({ body: { token, personid: person, teamid: team }});
    },
    uploadLogo: async (file) => {
        const session = Storage.getSession();
        const response = await Api.uploadTeamLogo({ session, file });
        return response.data.content.Location;
    }
};