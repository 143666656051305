import * as React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { AuthProvider, useAuth } from '@common/providers/Auth';

import SignUpClientPage from '@pages/account.signup';
import SignClientInPage from '@pages/account.signin';
import ForgotClientPage from '@pages/account.forgot';
import RecoverClientPage from '@pages/account.recover';
import VerifyClientPage from '@pages/account.verify';
import AccountClientPage from '@pages/account.main';
import TeamsPage from '@pages/teams.list';
import AddTeamPage from '@pages/teams.add';
import EditTeamPage from '@pages/teams.edit';
import TeamPage from '@pages/teams.detail';
import PeoplePage from '@pages/people.list';
import InvitePeoplePage from '@pages/people.invite';
import PeopleCongratulations from '@/pages/people.congratulations';
import NotFoundPage from '@pages/NotFound';

const ProtectedRoute = ({ children }) =>
{
    const { session } = useAuth();
    if (!session) return <Navigate to="/" replace />;
    return children;
};

const App = () =>
(
    <AuthProvider>
        <Routes>
            <Route index element={<SignClientInPage />} />
            <Route path="/cuenta">
                <Route index element={<ProtectedRoute><AccountClientPage /></ProtectedRoute>} />
                <Route path="password">
                    <Route path="crear" element={<SignUpClientPage />} />
                    <Route path="recordar" element={<ForgotClientPage />} />
                    <Route path="restablecer" element={<RecoverClientPage />} />
                    <Route path="verificar" element={<VerifyClientPage />} />
                </Route>
            </Route>
            <Route path="/equipos">
                <Route index element={<ProtectedRoute><TeamsPage /></ProtectedRoute>} />
                <Route path="crear" element={<ProtectedRoute><AddTeamPage /></ProtectedRoute>} />
                <Route path=":teamid" element={<ProtectedRoute><TeamPage /></ProtectedRoute>} />
                <Route path=":teamid/editar" element={<ProtectedRoute><EditTeamPage /></ProtectedRoute>} />
            </Route>
            <Route path="/personas">
                <Route index element={<ProtectedRoute><PeoplePage /></ProtectedRoute>} />
                <Route path="invitacion" element={<InvitePeoplePage />} />
                <Route path="enhorabuena" element={<PeopleCongratulations />} />
            </Route>
            <Route path="*" element={<NotFoundPage />} />
        </Routes>
    </AuthProvider>
);

export default App;