import { VStack, Button } from '@common/components';
import { FullCenterContainer } from '@common/layouts';
import RecoveredHeader from './RecoveredHeader';

const RecoveredAccount = ({ isLoading, onSignIn }) =>
(
    <FullCenterContainer>
        <VStack spacing="32px" w="100%" maxW="360px">
            <RecoveredHeader />
            <Button w="100%" colorScheme="buttons.primary" mt="24px"
            fontWeight="500"
            isLoading={isLoading}
            onClick={onSignIn}
            >
            Continuar
            </Button>
        </VStack>
    </FullCenterContainer>
);

export default RecoveredAccount;