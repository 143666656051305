import { groupBy, drop, map, head, concat } from 'lodash';
import { Box, Text } from '@common/components';
import Paragraph from './Paragraph';

const TroublesomeText = ({ toggle, text, competences }) =>
{
    const allParagraphs = toggle === 1 ? text.troublesomeunstable : text.leadershipteamwork;
    let personParagraphs = drop(allParagraphs);
    personParagraphs = map(groupBy(personParagraphs, 'body'), (value, key) => {
        if (value.length === 1) return {
            title: value[0].title,
            body: key
        };
        const last = value.pop();
        return {
            title: value.map((text) => text.title).join(', ') + ' y ' + last.title,
            body: key
        };
    });
    return (
        <Box flex="1" h="570px" maxH="570px" overflow="auto">
        {
            allParagraphs.length <= 1
            ? <Text as="i" fontWeight="500" color="#949CA9" fontSize="18px">
            La información sobre el equipo aparecerá en esta sección a medida que sus deportistas vayan completando el test.
            </Text>
            : concat(head(allParagraphs), personParagraphs).map((paragraph, index) => 
                <Paragraph key={index} index={index}
                status={competences.find((profile) => profile.person.name === paragraph.title)?.person?.status}
                title={paragraph.title} body={paragraph.body}
                />
            )
        }
        </Box>
    );
}

export default TroublesomeText;