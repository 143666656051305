import { Box, Text } from '@common/components';

const Paragraph = ({ title, body, index, status }) =>
(
    <Box mt={index !== 0 ? '16px' : '0px'}>
        <Text fontFamily="Inter" fontWeight="500" fontSize="18px" color={status === 'distortioned' ? '#8A5DB7' : '#344054'}>
        {title}
        </Text>
        <Text mt="16px" fontFamily="Inter" fontWeight="400" fontSize="16px" color={status === 'distortioned' ? '#8A5DB7' : '#344054'}>
        {body}
        </Text>
    </Box>
);

export default Paragraph;