import { Team } from './team';

export class Person
{
    constructor ({
        id,
        name,
        email,
        status,
        competences,
        team,
        teams
    }) {
        this.id = id;
        this.name = name;
        this.email = email;
        this.status = status;
        this.competences = competences;
        this.team = team;
        this.teams = teams;
    }
    static fromJson (json) {
        if (!json) return null;
        return new Person({
            id: json.id,
            name: json.name,
            email: json.email,
            status: json.status,
            competences: json.competences,
            teams: json.teams,
            team: Team.fromJson(json.team)
        });
    }
};

export class Invitation
{
    constructor ({
        token,
        person,
        team,
        state,
        logo,
        client
    }) {
        this.token = token;
        this.person = person;
        this.team = team;
        this.state = state;
        this.logo = logo;
        this.client = client;
    }
    static fromJson (json) {
        if (!json) return null;
        return new Invitation({
            person: json.person,
            team: json.team,
            token: json.token,
            state: json.state,
            logo: json.logo,
            client: json.client
        });
    }
};