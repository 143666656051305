import { useState, useCallback } from 'react';
import { useToast } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useQueryParam, StringParam } from 'use-query-params';
import links from '@common/links';

import { UserRepository } from '@repositories';

import { VStack } from '@common/components';
import { FullCenterContainer } from '@common/layouts';
import VerifyHeader from './components/VerifyHeader';
import VerifyForm from './components/VerifyForm';

const Forgot = () =>
{
    const toast = useToast();
    const navigate = useNavigate();
    const [email] = useQueryParam('email', StringParam);
    const [isVerifying, setIsVerifying] = useState(false);
    const [code, setCode] = useState('');
    const handleChangeCode = (value) => setCode(value);
    const handleVerifyAccount = useCallback(async () => {
        if (code.length === 0) return;
        setIsVerifying(true);
        try {
            await UserRepository.verify({ email, code });
            navigate(links.signinWithEmail(email));
        } catch (error) {
            setIsVerifying(false);
            return toast({
                title: 'Contraseña invalida.',
                status: 'error',
                duration: 3000,
                isClosable: true,
                position: 'top'
            });
        }
    }, [email, code, toast, navigate]);
    return (
        <FullCenterContainer>
            <VStack spacing="32px" w="100%" maxW="360px">
                <VerifyHeader />
                <VerifyForm
                code={code} onChangeCode={handleChangeCode}
                isVerifying={isVerifying}
                onVerify={handleVerifyAccount}
                />
            </VStack>
        </FullCenterContainer>
    );
}

export default Forgot;