import React, { useContext, createContext, useState } from 'react';
import { useToast } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { UserRepository } from '@repositories';
import { LoadingContainer } from '@common/layouts';
import links from '@common/links';

export const AuthContext = createContext(null);
export const AuthProvider = ({ children }) => 
{
    const navigate = useNavigate();
    const toast = useToast();
    const [isLoading, setIsLoading] = useState(false);
    const [session, setSession] = useState(UserRepository.getSession());

    const handleSignIn = async ({ email, password }) => {
        try {
            setIsLoading(true);
            const session = await UserRepository.signIn({ email, password });
            setSession(session);
            navigate(links.teams);
        } catch (error) {
            toast({
                title: 'Email o contraseña inválida.',
                status: 'error',
                duration: 3000,
                isClosable: true,
                position: 'top'
            });
        }
        setIsLoading(false);
    };
    const handleSignOut = async () => {
        setSession(null);
    };

    if (isLoading) return <LoadingContainer />
    return (
        <AuthContext.Provider value={{ session, onSignIn: handleSignIn, onSignOut: handleSignOut }}>
        {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);