import { useState } from 'react';
import { VStack, FormInput, Text, FormInputGroup, IconButton, FormInputRightElement, Icon } from ".";

const InputForm = ({ text, id, type, placeholder, value, focusBorderColor, onChange }) =>
{
    const handleChange = (event) => {
        event.preventDefault();
        onChange(event.target.value);
    };
    const [show, setShow] = useState(false);
    const handleShowPassword = () => setShow(!show);
    return (
        <VStack spacing="6px" w="100%">
            <Text w="100%" fontSize="14px">{text}</Text>
            <FormInputGroup size="md">
                <FormInput id={id} type={type !== 'password' ? type : show ? 'text' : 'password'} placeholder={placeholder}
                focusBorderColor={focusBorderColor}
                value={value}
                onChange={handleChange}
                />
                { type === 'password'
                    ?   <FormInputRightElement>
                            <IconButton h="1.75rem" size="sm"
                            onClick={handleShowPassword}
                            variant="ghost"
                            icon={<Icon icon={`${show ? 'hide' : 'show'}-password`} color="#9CA6C1" size="22px" />}
                            />
                        </FormInputRightElement>
                    : null
                }
            </FormInputGroup>
        </VStack>
    );
};

export default InputForm;