import React from 'react';
import ReactDOM from 'react-dom';
import Root from './App';

ReactDOM.render(
	<React.StrictMode>
		<Root />
  	</React.StrictMode>,
	document.getElementById('root')
);
