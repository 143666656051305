import { formatLocale } from '../localization/i18n';

const VIPSCAN_URL = process.env.REACT_APP_VIPSCAN_URL;
const VIPSCAN_CALLBACK_URL = process.env.REACT_APP_VIPSCAN_CALLBACK_URL;

const links = {
    home: '/',
    signin: '/',
    signinWithEmail: (email) => `/?email=${encodeURIComponent(email)}`,
    verifypassword: (email) => `/cuenta/password/verificar?email=${encodeURIComponent(email)}`,
    forgotpassword: '/cuenta/password/recordar',
    setpassword: (email) => `/cuenta/password/restablecer?email=${encodeURIComponent(email)}`,
    teams: '/equipos',
    team: (teamid) => `/equipos/${teamid}`, 
    addteam: '/equipos/crear',
    editteam: (teamid) => `/equipos/${teamid}/editar?tab=1`,
    addmember: (teamid) => `/equipos/${teamid}/editar?tab=2`,
    people: '/personas',
    account: '/cuenta',
    vipscan: ({ locale = 'es', personId, age, gender, education, token }) => {
        switch (formatLocale(locale)) {
            case 'en': return `${VIPSCAN_URL}?test=90B98BD3-0BFA-4CDB-BDE7-E988DB141B27&empresacodigo=5001&idioma=1&testid=${personId}&age=${age}&gender=${gender}&education=${education}&callbackurl=${VIPSCAN_CALLBACK_URL}?token=${token}`;
            default: return `${VIPSCAN_URL}?testid=${personId}&age=${age}&gender=${gender}&education=${education}&callbackurl=${VIPSCAN_CALLBACK_URL}?token=${token}`;
        }        
    },
    congratulations: '/personas/enhorabuena',
    termsandconditions: 'https://uploads-ssl.webflow.com/632eedcce846c0e339dd1238/633e8ef5600734e739de436e_PASO-2.-DERECHO-DE-INFORMACION.docx.pdf',
    privacypolicy: 'https://uploads-ssl.webflow.com/632eedcce846c0e339dd1238/633e8ef1eb4f8e17b56d7bb4_politica-de-privacidad-Smartest.docx.pdf',
    cookiepolicy: 'https://uploads-ssl.webflow.com/632eedcce846c0e339dd1238/633e8eeaac6d2b6150c67f01_politica-cookies-Smartest.pdf'
};

export default links;