import links from '@common/links';

import { VStack, HStack, LinkButton, Icon } from '@common/components';
import { FullCenterContainer } from '@common/layouts';
import ForgotHeader from './ForgotHeader';
import ForgotForm from './ForgotForm';

const ForgotAccount = ({ email, onChangeEmail, isReseting, onReset }) =>
(
    <FullCenterContainer>
        <VStack spacing="32px" w="100%" maxW="360px">
            <ForgotHeader />
            <ForgotForm
            email={email} onChangeEmail={onChangeEmail}
            isReseting={isReseting}
            onReset={onReset}
            />
            <HStack w="100%" spacing="12px" justify="center">
                <Icon icon="arrow-left" color="#667085" size="20px" />
                <LinkButton to={links.signin}>
                Volver al inicio de sesión
                </LinkButton>
            </HStack>
        </VStack>
    </FullCenterContainer>
);

export default ForgotAccount;