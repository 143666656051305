import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import links from '@common/links';

import { UserRepository, TeamRepository } from '@repositories';

import { Header, Spinner, Footer } from '@common/components';
import { FullContainer } from '@common/layouts';
import Title from './components/Title';
import SocialMedia from './components/SocialMedia';
import Competences from './components/Competences';
import People from './components/People';

const Teams = () =>
{
    const { teamid } = useParams();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [team, setTeam] = useState();
    useEffect(() => {
        async function findTeam () {
            try {
                setTeam(await TeamRepository.findOne(teamid));
                setIsLoading(false);
            } catch (error) {
                UserRepository.signOut();
                navigate(links.signin);
            }
        }
        findTeam();
    }, [navigate, teamid]);

    return (
        <FullContainer
        header={<Header />}
        footer={<Footer />}
        >
        {
        isLoading 
        ? <Spinner mt="73px" />
        : <>
            <Title name={team.name} onAddMember={() => { navigate(links.addmember(teamid)); }} />
            <SocialMedia profiles={team.socialmedia} onEditTeam={() => { navigate(links.editteam(teamid)); }} />
            <Competences category={team.category} profiles={team.persons} status={team.status} text={team.score.competence} />
            <People category={team.category} profiles={team.persons} />
          </>
        }
        </FullContainer>
    );
}

export default Teams;