import { Box, Text } from '@common/components';

const Section = ({ title, subtitle }) =>
(
    <Box mt="24px" mb="20px">
        <Text fontSize="18px" fontWeight="500" color="#101828">
        {title}
        </Text>
        <Text fontSize="14px" fontWeight="400" color="#667085">
        {subtitle}
        </Text>
    </Box>
);

export default Section;