import { Flex, Box, IconButton, Spacer } from '@common/components';
import * as FeatherIcon from 'react-feather';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons';

const SocialMedia = ({ profiles, onEditTeam }) =>
(
    <Flex>
        <Box flex="1">
            <Flex>
            <Spacer />
            {
                profiles.twitter ? <IconButton
                variant="ghost"
                icon={<FontAwesomeIcon icon={faTwitter} color="#9CA6C1" size="lg" />}
                onClick={() => { window.open(profiles.twitter); }}
                /> : null
            }
            {
                profiles.instagram ? <IconButton
                variant="ghost"
                icon={<FontAwesomeIcon icon={faInstagram} color="#9CA6C1" size="lg" />}
                onClick={() => { window.open(profiles.instagram); }}
                /> : null
            }
            {
                profiles.linkedin ? <IconButton
                variant="ghost"
                icon={<FontAwesomeIcon icon={faLinkedin} color="#9CA6C1" size="lg" />}
                onClick={() => { window.open(profiles.linkedin); }}
                /> : null
            }
            </Flex>
        </Box>
        <Spacer />
        <Box>
            <IconButton
            ml="20px"
            variant="ghost"
            icon={<FeatherIcon.Edit color="#9CA6C1" size="20px" />}
            onClick={onEditTeam}
            />
        </Box>
    </Flex>
);

export default SocialMedia;