import { useState, useCallback } from 'react';
import { useToast } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useQueryParam, StringParam } from 'use-query-params';
import links from '@common/links';

import { UserRepository } from '@repositories';

import { VStack, HStack, LinkButton, Icon } from '@common/components';
import { FullCenterContainer } from '@common/layouts';
import SignUpHeader from './components/SignUpHeader';
import SignUpForm from './components/SignUpForm';

const SignUp = () =>
{
    const toast = useToast();
    const navigate = useNavigate();
    const [token] = useQueryParam('token', StringParam);
    const [isCreating, setIsCreating] = useState(false);
    const [password, setPassword] = useState('');
    const handleChangePassword = (value) => setPassword(value);
    const [confirm, setConfirm] = useState('');
    const handleChangeConfirm = (value) => setConfirm(value);
    const handleSignUp = useCallback(async () => {
        if (confirm.length === 0 || password.length === 0 || confirm !== password) return toast({
            title: 'Contraseña invalida.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            position: 'top'
        });
        if (password.length < 8) return toast({
            title: 'Requisitos de contraseña: 8 caracteres | al menos 1 numero | al menos 1 caracter especial | al menos 1 mayuscula | al menos 1 minuscula',
            status: 'error',
            duration: 8000,
            isClosable: true,
            position: 'top'
        });
        setIsCreating(true);
        try {
            const email = await UserRepository.signUp({ token, password });
            navigate(links.verifypassword(email));   
        } catch (error) {
            setIsCreating(false);
            toast({
                title: 'Requisitos de contraseña: 8 caracteres | al menos 1 numero | al menos 1 caracter especial | al menos 1 mayuscula | al menos 1 minuscula',
                status: 'error',
                duration: 8000,
                isClosable: true,
                position: 'top'
            });
        }
    }, [token, password, confirm, toast, navigate]);
    return (
        <FullCenterContainer>
            <VStack spacing="32px" w="100%" maxW="360px">
                <SignUpHeader />
                <SignUpForm
                password={password} onChangePassword={handleChangePassword}
                confirm={confirm} onChangeConfirm={handleChangeConfirm}
                isCreating={isCreating}
                onCreate={handleSignUp}
                />
                <HStack w="100%" spacing="12px" justify="center">
                    <Icon icon="arrow-left" color="#667085" size="20px" />
                    <LinkButton to={links.signin}>
                    Volver al inicio de sesión
                    </LinkButton>
                </HStack>
            </VStack>
        </FullCenterContainer>
    );
}

export default SignUp;