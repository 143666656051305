import { Api, Storage } from '@services';
import { Client } from '@entities';

export const ClientRepository =
{
    findMe: async () => {
        const session = Storage.getSession();
        const client = Storage.getClientId();
        const response = await Api.fetchClient({ client, session });
        return Client.fromJson(response.data.content);
    }
};