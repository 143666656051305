export class Client
{
    constructor ({
        id,
        name,
        email
    }) {
        this.id = id;
        this.name = name;
        this.email = email;
    }
    static fromJson (json) {
        if (!json) return null;
        return new Client({
            id: json.id,
            name: json.name,
            email: json.email
        });
    }
    static validPassword (text) {
        const passRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
        return passRegex.test(text);
    }
};