import { useCallback, useState } from 'react';
import { Box, TableContainer, Table, Thead, Tr, Th, Tbody, Text } from '@common/components';
import TableHeader from './TableHeader';
import TableRowCompetences from './TableRowCompetences';
import TableRowPersonality from './TableRowPersonality';
import TableRowTroublesome from './TableRowTroublesome';

const TablePersonality = () =>
(
    <>
    <Th>
        <Text fontSize="12px" fontFamily="Inter" color="#667085">Reactividad</Text>
    </Th>
    <Th>
        <Text fontSize="12px" fontFamily="Inter" color="#667085">Extroversión</Text>
    </Th>
    <Th>
        <Text fontSize="12px" fontFamily="Inter" color="#667085">Apertura Mental</Text>
    </Th>
    <Th>
        <Text fontSize="12px" fontFamily="Inter" color="#667085">Cordialidad</Text>
    </Th>
    <Th>
        <Text fontSize="12px" fontFamily="Inter" color="#667085">Responsabilidad</Text>
    </Th>
    </>
);

const TableCompetences = () =>
(
    <>
    <Th>
        <Text fontSize="12px" fontFamily="Inter" color="#667085">Liderazgo</Text>
    </Th>
    <Th>
        <Text fontSize="12px" fontFamily="Inter" color="#667085">Colaboración</Text>
    </Th>
    <Th>
        <Text fontSize="12px" fontFamily="Inter" color="#667085">Innovación</Text>
    </Th>
    <Th>
        <Text fontSize="12px" fontFamily="Inter" color="#667085">Responsabilidad</Text>
    </Th>
    </>
);

const TableTroublesome = () =>
(
    <>
    <Th>
        <Text fontSize="12px" fontFamily="Inter" color="#667085">Conflictividad</Text>
    </Th>
    <Th>
        <Text fontSize="12px" fontFamily="Inter" color="#667085">Reactividad</Text>
    </Th>
    <Th>
        <Text fontSize="12px" fontFamily="Inter" color="#667085">Liderazgo</Text>
    </Th>
    <Th>
        <Text fontSize="12px" fontFamily="Inter" color="#667085">Colaboración</Text>
    </Th>
    </>
);

const People = ({ category, profiles }) =>
{
    const [tab, setTab] = useState(category === 'VC' ? 'competences' : 'troublesome');
    const handleTabChange = useCallback((tab) => {
        setTab(tab);
    }, []);

    return (
        <Box border="1px solid" borderColor="#EAECF0" borderRadius="8px" mt="40px">
            <TableHeader tab={tab} number={profiles.length} category={category} onChange={handleTabChange} />
            <TableContainer>
                <Table colorScheme="gray">
                <Thead>
                    <Tr>
                        <Th>
                        <Text fontSize="12px" fontFamily="Inter" color="#667085">Nombre</Text>
                        </Th>
                        { tab === 'competences' ? <TableCompetences /> : null }
                        { tab === 'troublesome' ? <TableTroublesome /> : null }
                        { tab === 'dimensions' ? <TablePersonality /> : null }
                    </Tr>
                </Thead>
                <Tbody>
                {profiles.map((profile, index) => 
                    tab === 'competences' 
                    ? <TableRowCompetences
                    index={index}
                    key={profile.person.id}
                    person={profile.person}
                    competences={profile.competences}
                    />
                    : tab === 'troublesome'
                    ? <TableRowTroublesome
                    index={index}
                    key={profile.person.id}
                    person={profile.person}
                    competences={profile.troublesome}
                    />
                    : <TableRowPersonality
                    index={index}
                    key={profile.person.id}
                    person={profile.person}
                    personality={profile.personality}
                    /> 
                )}  
                </Tbody>
                </Table>
            </TableContainer>
        </Box>
    );
}

export default People;