import { useState } from 'react';
import { Box, HeadingText, SupportingText, Flex, IconButton, Spacer, Tooltip, Icon } from '@common/components';

const TeamTitle = ({ team, onViewTeam }) =>
{
    const [isOpen, setIsTooltipOpen] = useState();
    const handleOver = () => setIsTooltipOpen(true);
    const handleOut = () => setIsTooltipOpen(false);
    return (
        <Flex mt="73px">
            <Box>
                <HeadingText>
                {team}
                </HeadingText>
                <SupportingText>
                Visualiza, analiza y decide.
                </SupportingText>
            </Box>
            <Spacer />
            <Tooltip hasArrow isOpen={isOpen}
            borderRadius="8px" p="12px" placement="left" 
            label="Acceso a la página de este equipo" bg="#222C45" color="white">
                <Box w="16px" h="45px" onMouseEnter={handleOver} onMouseLeave={handleOut}>
                    <Icon icon="help" size="16px" color="#98A2B3" />
                </Box>    
            </Tooltip>
            <Box w="1px" />
            <IconButton
                onMouseEnter={handleOver} onMouseLeave={handleOut}
                variant="ghost"
                icon={<Icon icon="external-link" color="#5A81A4" size="30px" />}
                onClick={onViewTeam} />
        </Flex>
    );
}

export default TeamTitle;