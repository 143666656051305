import { useState } from 'react';
import { FormInput, FormInputGroup, IconButton, FormInputRightElement, Icon } from ".";

const PasswordInput = ({ id, isDisabled, type, placeholder, value, focusBorderColor, onChange, maxW, color, bgColor }) =>
{
    const handleChange = (event) => {
        event.preventDefault();
        onChange(event.target.value);
    };
    const [show, setShow] = useState(false);
    const handleShowPassword = () => setShow(!show);
    return (
        <FormInputGroup size="md" maxW={maxW}>
            <FormInput id={id} type={type !== 'password' ? type : show ? 'text' : 'password'}
            placeholder={placeholder}
            focusBorderColor={focusBorderColor} color={color} bg={bgColor}
            isDisabled={isDisabled} value={value} onChange={handleChange}
            />
            { type === 'password'
                ?   <FormInputRightElement>
                        <IconButton h="1.75rem" size="sm"
                        onClick={handleShowPassword}
                        variant="ghost"
                        icon={<Icon icon={`${show ? 'hide' : 'show'}-password`} color="#9CA6C1" size="22px" />}
                        />
                    </FormInputRightElement>
                : null
            }
        </FormInputGroup>
    );
};

export default PasswordInput;