import { VStack, HeadingText, SupportingText, Icon, Center } from '@common/components';

const ForgotHeader = () =>
(
    <VStack spacing="12px" textAlign="center">
        <Center bg="#F7FAFC" borderRadius="full" boxSize="56px">
            <Icon icon="key" color="#5A81A4" size="23px" />
        </Center>
        <HeadingText>¿Has olvidado tu contraseña?</HeadingText>
        <SupportingText>No te preocupes, te enviaremos las instrucciones de restablecimiento.</SupportingText>
    </VStack>
);

export default ForgotHeader;