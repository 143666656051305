import { useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Flex, Logo, Spacer, PrimaryButton, SecondaryButton, IconButton } from '@common/components';
import links from '@common/links';
import * as FeatherIcon from 'react-feather';

const Header = ({ teamsIsActive, peopleIsActive }) =>
{
    const navigate = useNavigate();
    const location = useLocation();
    const handleTeamsClick = useCallback(() => {
        navigate(links.teams);
    }, [navigate]);
    const handlePeopleClick = useCallback(() => {
        navigate(links.people);
    }, [navigate]);
    const handleAddTeamClick = useCallback(() => {
        if (location.pathname === links.addteam) return navigate(0);
        navigate(links.addteam);
    }, [navigate, location]);
    const handleSettingsClick = useCallback(() => {
        navigate(links.account);
    }, [navigate]);

    return (
        <Box
        bg="white"
        pos="fixed" top={0} zIndex={1000}
        borderBottom="1px"
        borderBottomColor="others.divider"
        w="100%" h="73px"
        >
            <Flex px="1em" flexDirection="row" maxW="1216px" mx="auto" h="100%">
                <Flex align="center" justify="center">
                    <Logo />
                    <Box ml="50px">
                        <PrimaryButton id="header-nav-teams"
                        isActive={teamsIsActive} onClick={handleTeamsClick}>
                        Equipos
                        </PrimaryButton>
                    </Box>
                    <Box ml="20px">
                        <PrimaryButton id="header-nav-people"
                        isActive={peopleIsActive} onClick={handlePeopleClick}>
                        Personas
                        </PrimaryButton>
                    </Box>
                </Flex>
                <Spacer />
                <Flex align="center">
                    <SecondaryButton id="header-nav-addteam"
                    icon="zap" onClick={handleAddTeamClick}>
                    Nuevo equipo
                    </SecondaryButton>
                    <IconButton
                    id="header-nav-account"
                    ml="20px" variant="ghost"
                    icon={<FeatherIcon.Settings color="#9CA6C1" size="20px" />}
                    onClick={handleSettingsClick}
                    />
                </Flex>
            </Flex>
        </Box>
    );
}

export default Header;