import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import links from '@common/links';

import { UserRepository, TeamRepository } from '@repositories';

import { Header, Spinner, Footer } from '@common/components';
import { FullContainer } from '@common/layouts';
import TeamsTitle from './components/TeamsTitle';
import TeamsTable from './components/TeamsTable';

const Teams = () =>
{
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [teams, setTeams] = useState([]);
    useEffect(() => {
        async function findTeams () {
            try {
                setTeams(await TeamRepository.findAll());
                setIsLoading(false);
            } catch (error) {
                UserRepository.signOut();
                navigate(links.signin);
            }
        }
        findTeams();
    }, [navigate]);

    return (
        <FullContainer
        header={<Header teamsIsActive />}
        footer={<Footer />}
        >
            <TeamsTitle />
            {
                isLoading 
                ? <Spinner mt="40px" />
                : <TeamsTable teams={teams} />
            }
        </FullContainer>
    );
}

export default Teams;