import { Box, VStack, InputForm, Button, LinkButton } from '@common/components';
import links from '@common/links';

const SignInForm = ({ email, onChangeEmail, password, onChangePassword, isAuthenticating, onSignIn }) =>
(
    <Box w="100%" fontSize="16px">
    <form onSubmit={onSignIn}>
        <VStack spacing="20px" w="100%">
            <InputForm id="email"
            type="email"
            text="Correo electrónico"
            placeholder="Introduce tu correo electrónico"
            value={email}
            onChange={onChangeEmail}
            />
            <InputForm id="password"
            type="password"
            text="Contraseña"
            placeholder="Introduce tu contraseña"
            value={password}
            onChange={onChangePassword}
            />
        </VStack>
        <Box my="24px" w="100%" align="center">
            <LinkButton to={links.forgotpassword}>
            He olvidado la contraseña
            </LinkButton>
        </Box>
        <Button id="signin-form-button" w="100%" colorScheme="buttons.primary"
        fontWeight="500"
        isLoading={isAuthenticating}
        type="submit"
        >
        Acceder
        </Button>
        </form>
    </Box>
);

export default SignInForm;