import { Box } from '@common/components';
import Status from './Status';
import Chart from './SportsChart';

const CompetencesChart = ({ toggle, competences, status }) =>
{
    status = toggle === 1 ? status.competence.troublesomeunstable : status.competence.leadershipteamwork;
    return (
        <Box flex="1">
            <Status status={status} />
            <Box h="15px" />
            <Chart display={toggle} status={status} competences={competences}  />
        </Box>
    );
}

export default CompetencesChart;