import { Box, Flex } from '@common/components';
import TeamDivider from './TeamDivider';

const TeamPanel = ({ info, form, mt }) =>
(
    <Box w="100%" mt={mt}>
        <TeamDivider />
        <Flex>
            <Box w="280px" >
            {info}
            </Box>
            <Box w="32px" />
            <Box flex="1">
            {form}
            </Box>
        </Flex>
    </Box>
);

export default TeamPanel;