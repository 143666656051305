import { Flex, Box, HeadingText, SupportingText, Button, HStack, Icon } from '@common/components';

const Title = ({ name, onAddMember }) =>
(
    <Flex mt="73px">
        <Box id="team-title" flex="1">
            <HeadingText>
            {name}
            </HeadingText>
            <SupportingText>
            Visualiza, analiza y decide.
            </SupportingText>
        </Box>
        <Box>
            <Button w="100%" colorScheme="buttons.primary"
            fontWeight="500"
            onClick={onAddMember}
            >
                <HStack spacing="8px">
                <Icon icon="plus" color="white" />
                <Box>Persona</Box>
                </HStack>
            </Button>
        </Box>
    </Flex>
);

export default Title;