import { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';
import links from '@common/links';

import { UserRepository, ClientRepository } from '@repositories';

import { Header, Spinner, Footer, Box, ButtonGroup, Tab } from '@common/components';
import { FullContainer } from '@common/layouts';
import Title from './components/Title';
import ClientPersonalData from './components/PersonalData';
import ClientPassword from './components/Password';
import SignOut from './components/SignOut';

const Account = () =>
{
    const navigate = useNavigate();
    const toast = useToast();
    const [isLoading, setIsLoading] = useState(true);
    const [client, setClient] = useState([]);
    useEffect(() => {
        async function findMe () {
            try {
                setClient(await ClientRepository.findMe());
            } catch (error) {
                if (error.response?.status === 401) {
                    UserRepository.signOut();
                    navigate(links.signin);
                }
            }
            setIsLoading(false);
        }
        findMe();
    }, [navigate]);

    const [tabSelected, setTabSelected] = useState('1');
    const handleTabClick = useCallback((tab) => {
        setTabSelected(tab);
    }, []);

    const [isUpdating, setIsUpdating] = useState(false);
    const [oldPass, setOldPass] = useState('');
    const onChangeOldPass = useCallback((pass) => { setOldPass(pass); }, []);
    const [newPass, setNewPass] = useState('');
    const onChangeNewPass = useCallback((pass) => { setNewPass(pass); }, []);
    const [confirmPass, setConfirmPass] = useState('');
    const onChangeConfirmPass = useCallback((pass) => { setConfirmPass(pass); }, []);
    const updatePass = useCallback(async () => {
        if (oldPass === newPass) return toast({
            title: 'La contraseña nueva debe de ser diferente a la actual.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            position: 'top'
        });
        setIsUpdating(true);
        try {
            await UserRepository.updatePassword({ oldPassword: oldPass, newPassword: newPass });
            setOldPass('');
            setNewPass('');
            setConfirmPass('');
        } catch (error) {
            if (error.response?.status === 401) {
                UserRepository.signOut();
                navigate(links.signin);
            } else toast({
                title: 'Error actualizando la contraseña. La contraseña no se ha actualizado.',
                status: 'error',
                duration: 3000,
                isClosable: true,
                position: 'top'
            });
        }
        setIsUpdating(false);
    }, [newPass, oldPass, toast, navigate]);
    const signOut = useCallback(() => {
        UserRepository.signOut();
        navigate(links.signin);
    }, [navigate]);

    return (
        <FullContainer
        header={<Header />}
        footer={<Footer />}
        >
            <SignOut onSignOut={signOut} />
            <Title />
            {
                isLoading 
                ? <Spinner mt="40px" />
                : <>
                    <Box mt="25px" borderBottom="1px solid #EAECF0">
                        <ButtonGroup gap="24px">
                            <Tab id="1" isSelected={tabSelected === "1"} onChange={handleTabClick}>
                            Mis Datos
                            </Tab>
                            <Tab id="2" isSelected={tabSelected === "2"} onChange={handleTabClick}>
                            Contraseña
                            </Tab>
                        </ButtonGroup>
                    </Box>
                    {tabSelected === '1' ?
                    <ClientPersonalData client={client} />
                    : null
                    }
                    {tabSelected === '2' ?
                    <ClientPassword
                    isUpdating={isUpdating} onUpdate={updatePass}
                    oldPass={oldPass} onChangeOldPass={onChangeOldPass}
                    newPass={newPass} onChangeNewPass={onChangeNewPass}
                    confirmPass={confirmPass} onChangeConfirmPass={onChangeConfirmPass}
                    />
                    : null
                    }
                </>
            }
        </FullContainer>
    );
}

export default Account;