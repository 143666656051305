import React from 'react';
import { VerticalContainer } from '@common/layouts';
import { Flex, Footer, Spinner } from '@common/components';
import Header from './Header';
import { cleanChildren } from '../helpers/layout.helper';


const resolveChildren = (children) => {
    return cleanChildren(children).reduce((memo, element) => {
        if(element.type === Layout.Content) memo.content = element
        return memo;
    }, {
        content: null,
    });
}

export const Layout = ({ children, isLoading, }) => {
    const { content } = resolveChildren(children);
    return (
        <VerticalContainer
        header={<Header />}
        footer={<Footer onlyLegal />}
        >
            {isLoading
            ? <Spinner />
            : content
            }
        </VerticalContainer>
    );
};

Layout.Content = ({ children }) => <Flex w="100%" justify="center" children={children} />;