import { VStack, HeadingText, SupportingText, Icon, Center } from '@common/components';

const SignUpHeader = () =>
(
    <VStack spacing="12px" textAlign="center">
        <Center bg="#F7FAFC" borderRadius="full" boxSize="56px">
            <Icon icon="key" color="#5A81A4" size="23px" />
        </Center>
        <HeadingText>Establecer una nueva contraseña</HeadingText>
        <SupportingText>Tu nueva contraseña debe ser diferente a las utilizadas anteriormente.</SupportingText>
    </VStack>
);

export default SignUpHeader;