import { useControllableState } from '@chakra-ui/react';
import { Box, Text, HStack, SelectButton, Flex } from '@common/components';
import CompetencesText from './CompetencesText';
import CompetencesChart from './CompetencesChart';
import TroublesomeText from './TroublesomeText';
import TroublesomeChart from './TroublesomeChart';

const VCProfile = ({ profiles, status, text }) =>
{
    const [toggle, setToggle] = useControllableState({ defaultValue: 1 });
    return (
        <Box mt="18px">
            <Text fontFamily="Inter" fontWeight="500" fontSize="24px" color="#344054">
            CAPACIDADES
            </Text>
            <HStack mt="10px" spacing="12px">
                <SelectButton isActive={toggle === 1} onClick={() => setToggle(1)}>
                Innovación y Responsabilidad
                </SelectButton>
                <SelectButton isActive={toggle === 2} onClick={() => setToggle(2)}>
                Liderazgo y Colaboración
                </SelectButton>
            </HStack>
            <Flex mt="34px">
                <CompetencesText toggle={toggle} competences={profiles} text={text} />
                <Box w="60px"></Box>
                <CompetencesChart toggle={toggle} competences={profiles} status={status} />
            </Flex>
        </Box>
    );
};

const SportsProfile = ({ profiles, status, text }) =>
{
    const [toggle, setToggle] = useControllableState({ defaultValue: 1 });
    return (
        <Box mt="18px">
            <Text fontFamily="Inter" fontWeight="500" fontSize="24px" color="#344054">
            CAPACIDADES
            </Text>
            <HStack mt="10px" spacing="12px">
                <SelectButton isActive={toggle === 1} onClick={() => setToggle(1)}>
                Conflictividad y Reactividad
                </SelectButton>
                <SelectButton isActive={toggle === 2} onClick={() => setToggle(2)}>
                Liderazgo y Colaboración
                </SelectButton>
            </HStack>
            <Flex mt="34px">
                <TroublesomeText toggle={toggle} competences={profiles} text={text} />
                <Box w="60px"></Box>
                <TroublesomeChart toggle={toggle} competences={profiles} status={status} />
            </Flex>
        </Box>
    );
};

const Competences = ({ category, profiles, status, text }) =>
{
    if (category === 'SPORTS') return <SportsProfile profiles={profiles} status={status} text={text} />
    return <VCProfile profiles={profiles} status={status} text={text} />
}

export default Competences;