import { Box } from '@common/components';
import { Container } from '@common/layouts';

const FullContainer = ({ children, header, footer, pb }) =>
(
    <>
    {header ? header : null}
    <Container px="2.5em" maxW="1216px" minH="100vh" centerContent>
        <Box minH="100vh" pt="40px" pb={ pb ? pb : '120px'} minW="100%">
        {children}
        </Box>
        {footer ? footer : null}
    </Container>
    </>
);

export default FullContainer;