import { Flex, Spacer, Tooltip, Box, Icon, Center, Text } from '@common/components';

const StatusSuccess = () =>
(
    <Flex> 
        <Spacer />
        <Tooltip hasArrow borderRadius="8px" p="12px" placement="top-end" label="El análisis de las competencias presentadas en esta sección, indican que este equipo está equilibrado." bg="#222C45" color="white">
            <Box w="16px">
                <Icon icon="help" size="16px" color="#98A2B3" />
            </Box>    
        </Tooltip>
        <Box w="10px" />
        <Center w="52px" h="52px" borderRadius="8px" border="2px solid #70B37D" bg="#F6FEF9">
            <Icon icon="success" size="18px" color="#319044" />
        </Center>
    </Flex>
);

const StatusWarning = () =>
(
    <Flex> 
        <Spacer />
         <Tooltip hasArrow borderRadius="8px" p="12px" placement="top-end" label="El análisis de las competencias presentadas en esta sección, indican que este equipo necesita atención." bg="#222C45" color="white">
            <Box w="16px">
                <Icon icon="help" size="16px" color="#98A2B3" />
            </Box>    
        </Tooltip>
        <Box w="10px" />
        <Center w="52px" h="52px" borderRadius="8px" border="2px solid #F6AD23" bg="#FFF9EE">
            <Icon icon="warning" size="18px" color="#DD9208" />
        </Center>
    </Flex>
);

const StatusDanger = () =>
(
    <Flex> 
        <Spacer />
        <Tooltip hasArrow borderRadius="8px" p="12px" placement="top-end" label="El análisis de las competencias presentadas en esta sección, indican que este equipo es de alto riesgo." bg="#222C45" color="white">
            <Box w="16px">
                <Icon icon="help" size="16px" color="#98A2B3" />
            </Box>    
        </Tooltip>
        <Box w="10px" />
        <Center w="52px" h="52px" borderRadius="8px" border="2px solid #8A5DB7" bg="#F3EFF8">
            <Icon icon="danger" size="18px" color="#8A5DB7" />
        </Center>
    </Flex>
);

const Status = ({ status }) =>
{
    if (status === 'success') return <StatusSuccess />
    if (status === 'warning') return <StatusWarning />
    if (status === 'danger') return <StatusDanger />
    return <Text as="i" fontWeight="500" color="#949CA9" fontSize="14px" ml="35px">
        La posición de cada persona en el gráfico aparecerá una vez terminen el test.
        </Text>;
};

export default Status;