import { Api, Storage } from '@services';
import { Person, Invitation } from '@entities';

export const PeopleRepository =
{
    findAll: async () => {
        const session = Storage.getSession();
        const client = Storage.getClientId();
        const response = await Api.fetchPeople({ client, session });
        return {
            category: response.data.content.category,
            people: response.data.content.people.map((person) => Person.fromJson(person))
        };
    },
    sendInvitations: async (teamid, emails) => {
        const session = Storage.getSession();
        const clientid = Storage.getClientId();
        const response = await Api.sendPeopleInvitations({ body: { clientid, teamid, emails }, session });
        return response.data.content.map((person) => Person.fromJson(person));
    },
    fetchInvitation: async (token) => {
        const response = await Api.claimPeopleInvitation({ body: { token }});
        return Invitation.fromJson(response.data.content);
    },
    update: async ({ token, personid, name, age, gender, education, dni }) => {
        const response = await Api.updatePersonWithToken({ person: personid, body: { token, name, age, gender, education, dni }});
        return Person.fromJson(response.data.content);
    },
    copy: async ({ token, personid, dni }) => {
        const response = await Api.copyPersonWithToken({ person: personid, body: { token, dni }});
        return Person.fromJson(response.data.content);
    },
    finishTest: async (token) => {
        await Api.deletePeopleInvitation({ data: { token }});
    }
};