import { Box, PasswordInput, Text, Flex, Spacer, Button } from '@common/components';
import Section from './Section';
import Panel from './Panel';

const passRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");

const Password = ({ isUpdating, onUpdate, oldPass, newPass, confirmPass, onChangeOldPass, onChangeNewPass, onChangeConfirmPass }) =>
(
    <Box>
        <Section title="Contraseña" subtitle="Introduce tu contraseña actual para cambiarla." />
        <Box h="1px" bg="#EAECF0" />
        <Panel info="Contraseña actual" form={
            <PasswordInput maxW="512px"
            value={oldPass} onChange={(value) => { onChangeOldPass(value); }}
            isDisabled={isUpdating} type="password"
            focusBorderColor="#70B37D" color="#667085" bg="white"  />
        } />
        <Box h="1px" bg="#EAECF0" />
        <Panel info="Nueva contraseña" form={
            <Box>
                <PasswordInput maxW="512px"
                value={newPass} onChange={(value) => { onChangeNewPass(value); }}
                isDisabled={isUpdating} type="password"
                focusBorderColor={newPass.length === 0 || !passRegex.test(newPass) ? '#CD3227' : '#70B37D'}
                color="#667085" bg="white"  />
                <Text maxW="512px" fontSize="14px" color="#667085" mt="6px">
                Tu nueva contraseña debe tener más de 8 caracteres, al menos 1 número, al menos 1 caracter especial, al menos 1 mayúscula y al menos 1 minúscula.
                </Text>
            </Box>
        } />
        <Box h="1px" bg="#EAECF0" />
        <Panel info="Confirmar la nueva contraseña" form={
            <PasswordInput maxW="512px"
            value={confirmPass} onChange={(value) => { onChangeConfirmPass(value); }}
            isDisabled={isUpdating} type="password"
            focusBorderColor={confirmPass.length === 0 || newPass !== confirmPass ? '#CD3227' : '#70B37D'}
            color="#667085" bg="white"  />
        } />
        <Box h="1px" bg="#EAECF0" />
        <Flex mt="16px">
            <Spacer />
            <Button colorScheme="buttons.primary"
            fontWeight="500"
            isDisabled={newPass.length === 0 || newPass !== confirmPass}
            isLoading={isUpdating}
            onClick={onUpdate}
            >
            Actualizar contraseña
            </Button>
        </Flex>
    </Box>
);

export default Password;