import { Box, TableContainer, Table, Thead, Tr, Th, Tbody, Text } from '@common/components';
import TableHeader from './PeopleTableHeader';
import TableRow from './VCPeopleTableRow';

const PeopleTable = ({ people }) =>
(
    <Box id="people-table" border="1px solid" borderColor="#EAECF0" borderRadius="8px" mt="40px">
        <TableHeader number={people.length} />
        <TableContainer>
            <Table  colorScheme="gray">
            <Thead>
                <Tr>
                    <Th>
                    <Text fontSize="12px" fontFamily="Inter" color="#667085">Nombre</Text>
                    </Th>
                    <Th>
                    <Text fontSize="12px" fontFamily="Inter" color="#667085">Liderazgo</Text>
                    </Th>
                    <Th>
                    <Text fontSize="12px" fontFamily="Inter" color="#667085">Colaboración</Text>
                    </Th>
                    <Th>
                    <Text fontSize="12px" fontFamily="Inter" color="#667085">Innovación</Text>
                    </Th>
                    <Th>
                    <Text fontSize="12px" fontFamily="Inter" color="#667085">Responsabilidad</Text>
                    </Th>
                    <Th>
                    <Text fontSize="12px" fontFamily="Inter" color="#667085">Equipo</Text>
                    </Th>
                </Tr>
            </Thead>
            <Tbody>
            {people.map((person, index) => 
                <TableRow
                index={index}
                key={`${person.id}:${person.team.id}`}
                person={person}
                />
            )}  
            </Tbody>
            </Table>
        </TableContainer>
    </Box>
);

export default PeopleTable;