import { Button } from '@common/components';

const PrimaryButton = ({ id, children, isActive, onClick }) =>
(
    <Button
    id={id}
    variant="primary"
    isActive={isActive}
    onClick={onClick}>
    {children}
    </Button>
);


export default PrimaryButton;