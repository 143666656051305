import { Box, HeadingText, SupportingText } from '@common/components';

const Title = () =>
(
    <Box mt="73px">
        <HeadingText>
        ¡Hola!
        </HeadingText>
        <SupportingText>
        Visualiza, analiza y decide.
        </SupportingText>
    </Box>
);

export default Title;