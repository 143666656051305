import { Box, HeadingText } from '@common/components';

const Title = () =>
(
    <Box>
        <HeadingText>
        Ajustes
        </HeadingText>
    </Box>
);

export default Title;