import { Td, Text, HStack } from '@common/components';

const NotAcceptedStatus = ({ cols = 4 }) =>
(
    <Td colSpan={cols}>
        <HStack spacing="4px">
            <Text as="i" fontFamily="Inter" fontSize="14px" fontWeight="500" color="#8A5DB7">
            Esta persona aún
            </Text>
            <Text as="i" style={{ textDecoration: 'underline' }} fontFamily="Inter" fontSize="14px" fontWeight="500" color="#8A5DB7">
            no ha aceptado
            </Text>
            <Text as="i" fontFamily="Inter" fontSize="14px" fontWeight="500" color="#8A5DB7">
            la invitación
            </Text>
        </HStack>
    </Td>
);

export default NotAcceptedStatus;