import { Box } from '@common/components';
import { Container } from '@common/layouts';

const FullContainer = ({ children, header, footer, pb }) =>
(
    <Container minH="100vh" maxW="1216px" centerContent>
        {header ? header : null}
        <Box minH="100vh" pt="40px" pb={ pb ? pb : '120px'} minW="100%">
        {children}
        </Box>
        {footer ? footer : null}
    </Container>
);

export default FullContainer;