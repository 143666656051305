import { Box, InputForm, Button, VStack, Text } from '@common/components';
import { Client } from '@entities';

const SignUpForm = ({ password, onChangePassword, confirm, onChangeConfirm, isCreating, onCreate }) =>
(
    <Box w="100%" fontSize="16px">
        <VStack spacing="20px" w="100%">
            <Box>
                <InputForm id="password"
                type="password"
                text="Contraseña"
                placeholder="Introduce tu contraseña"
                focusBorderColor={password.length === 0 || !Client.validPassword(password) ? '#CD3227' : '#70B37D'}
                value={password} onChange={onChangePassword}
                />
                <Text maxW="512px" fontSize="14px" color="#667085" mt="6px">
                Tu nueva contraseña debe tener más de 8 caracteres, al menos 1 número, al menos 1 caracter especial, al menos 1 mayúscula y al menos 1 minúscula.
                </Text>
            </Box>
            <InputForm id="confirm"
            type="password"
            text="Confirmar contraseña"
            placeholder="Confirma tu contraseña"
            focusBorderColor={confirm.length === 0 || password !== confirm ? '#CD3227' : '#70B37D'}
            value={confirm} onChange={onChangeConfirm}
            />
        </VStack>
        <Button w="100%" colorScheme="buttons.primary" mt="24px"
        fontWeight="500"
        isDisabled={password.length === 0 || password !== confirm}
        isLoading={isCreating}
        onClick={onCreate}
        >
        Guardar contraseña
        </Button>
    </Box>
);

export default SignUpForm;