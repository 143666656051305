import { Tr, Td, Avatar, Circle, HStack, VStack, Text, Progress } from '@common/components';
import PendingStatus from './TableCellPending';
import NotAcceptedStatus from './TableCellNotAccepted';

const Person = ({ name, email }) =>
(
    <HStack spacing="12px">
        {!name
            ? <Avatar size="sm" name={name} />
            : <Circle
            size="32px"
            border="1px solid #C4C4C4"
            bg="#F9FAFB"
            >
                <Text fontSize="16px" fontWeight="500" color="#475467">
                {name?.match(/(\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase()}
                </Text>
            </Circle>
        }
        <VStack spacing="0px" align="left">
            <Text fontFamily="Inter" fontSize="14px" fontWeight="500" color="#101828">{name}</Text>
            <Text fontFamily="Inter" fontSize="14px" fontWeight="400" color="#667085">{email}</Text>
        </VStack>
    </HStack>
);

const Competence = ({ score, color = 'yellow' }) =>
{
    if (score === undefined) return null;
    if (score > 70) color = 'green';
    if (score <= 30) color = 'red';
    return (
        <HStack spacing="12px">
            <Progress size="sm" w="60px" value={score} colorScheme={color} borderRadius="4px" />
            <Text fontFamily="Inter" fontSize="14px" fontWeight="500" color="#344054">{`${~~score}%`}</Text>
        </HStack>
    );
};

const TableRow = ({ person, competences, index }) =>
(
    <Tr bg={index % 2 ? 'white' : '#F9FAFB'}>
        <Td>
            <Person name={person.name} email={person.email} />
        </Td>
        {
            person.status === 'pending'
            ? <PendingStatus />
            : person.status === 'filled' && !competences
            ? <NotAcceptedStatus />
            : <>
            <Td>
                <Competence score={competences?.leadership} />
            </Td>
            <Td>
                <Competence score={competences?.teamwork} />
            </Td>
            <Td>
                <Competence score={competences?.innovation} />
            </Td>
            <Td>
                <Competence score={competences?.responsibility} />
            </Td>
            </>
        }
    </Tr>
);

export default TableRow;