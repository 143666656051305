import { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';
import links from '@common/links';
import { trim, compact } from 'lodash';

import { UserRepository, TeamRepository, PeopleRepository } from '@repositories';

import { Header, Footer, Box, HeadingText, ButtonGroup, Tab } from '@common/components';
import { FullContainer } from '@common/layouts';
import TeamTitle from './components/TeamTitle';
import TeamDescription from './components/TeamDescription';
import TeamMembers from './components/TeamMembers';
import ModalUnsavedTeam from './components/ModalUnsavedTeam';
import ModalSavedTeam from './components/ModalSavedTeam';
import ModalSavedData from './components/ModalSavedData';

const AddTeam = () =>
{
    const navigate = useNavigate();
    const toast = useToast();
    const [isLoading, setIsLoading] = useState(false);
    const [team, setTeam] = useState();
    const [members, setMembers] = useState('');
    const [invitations, setInvitations] = useState([]);
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);
    const onChangeMembers = useCallback((text) => { setMembers(text); }, []);
    const [name, setName] = useState('');
    const onChangeName = useCallback((text) => { setName(text); }, []);
    const [web, setWeb] = useState('');
    const onChangeWeb = useCallback((text) => { setWeb(text); }, []);
    const [description, setDescription] = useState('');
    const onChangeDescription = useCallback((text) => { setDescription(text); }, []);
    const [twitter, setTwitter] = useState('');
    const onChangeTwitter = useCallback((text) => { setTwitter(text); }, []);
    const [instagram, setInstagram] = useState('');
    const onChangeInstagram = useCallback((text) => { setInstagram(text); }, []);
    const [linkedin, setLinkedin] = useState('');
    const onChangeLinkedin = useCallback((text) => { setLinkedin(text); }, []);
    const [logo, setLogo] = useState('');
    const onChangeLogo = useCallback((logo) => { setLogo(logo); }, []);

    const [tabSelected, setTabSelected] = useState("1");
    const handleTabClick = useCallback((tab) => {
        if (tab === "2" && !team && name.length === 0) return;
        if (tab === "2" && !team) return setOpenModalUnsavedTeam(true);
        setTabSelected(tab);
    }, [team, name]);

    const [locale, setLocale] = useState('es');
    const handleChangeLocale = useCallback(async (lang) => {
        setLocale(lang);
        await TeamRepository.update(team.id, { locale: lang });
    }, [team]);

    const [isOpenModalUnsavedTeam, setOpenModalUnsavedTeam] = useState();
    const handeOnCloseModalUnsavedTeam = useCallback(() => {
        setOpenModalUnsavedTeam(false);
    }, []);
    const [isOpenModalSavedTeam, setOpenModalSavedTeam] = useState();
    const handeOnCloseModalSavedTeam = useCallback(() => {
        setOpenModalSavedTeam(false);
    }, []);
    const [isOpenModalSavedMembers, setOpenModalSavedMembers] = useState();
    const handleOnCloseModalSavedMembers = useCallback(() => {
        setOpenModalSavedMembers(false);
        setIsTooltipOpen(true);
        setTimeout(() => setIsTooltipOpen(false), 5000);
    }, []);

    const handleAddTeam = useCallback(async () => {
        if (isOpenModalUnsavedTeam) setOpenModalUnsavedTeam(false);
        setIsLoading(true);
        try {
            if (!team) {
                setTeam(await TeamRepository.add({
                    name, 
                    ...web.length > 0 && { web: `https://${web}` },
                    ...description.length > 0 && { description },
                    ...twitter.length > 0 && { twitter: `https://twitter.com/${twitter}` }, 
                    ...instagram.length > 0 && { instagram: `https://instagram.com/${instagram}` }, 
                    ...linkedin.length > 0 && { linkedin: `https://linkedin.com/company/${linkedin}` },
                    ...logo.length > 0 && { logo }
                }));
                if (members.length === 0) setOpenModalSavedTeam(true);
            } else {
                setTeam(await TeamRepository.update(team.id, {
                    name, 
                    ...web.length > 0 && { web: `https://${web}` }, 
                    ...description.length > 0 && { description }, 
                    ...twitter.length > 0 && { twitter: `https://twitter.com/${twitter}` }, 
                    ...instagram.length > 0 && { instagram: `https://instagram.com/${instagram}` }, 
                    ...linkedin.length > 0 && { linkedin: `https://linkedin.com/company/${linkedin}` },
                    ...logo.length > 0 && { logo }
                }));
            }
        } catch (error) {
            if (error.response?.status === 401) {
                UserRepository.signOut();
                navigate(links.signin);
            } else toast({
                title: error.response?.data?.error?.message || 'Error creando el nuevo equipo.',
                status: 'error',
                duration: 3000,
                isClosable: true,
                position: 'top'
            });
        }
        setIsLoading(false);
    }, [team, name, web, description, twitter, instagram, linkedin, logo, members, isOpenModalUnsavedTeam, navigate, toast]);
    const handleSendInvitations = useCallback(async () => {
        setIsLoading(true);
        try {
            const newInvitations = compact(members.split('\n').map((email) => trim(email)));
            await PeopleRepository.sendInvitations(team.id, newInvitations);
            setMembers('');
            setInvitations(invitations.concat(newInvitations));
            setOpenModalSavedMembers(true);
        } catch (error) {
            if (error.response?.status === 401) {
                UserRepository.signOut();
                navigate(links.signin);
            } else toast({
                title: 'Comprueba que los emails son correctos.',
                status: 'error',
                duration: 3000,
                isClosable: true,
                position: 'top'
            });
        }
        setIsLoading(false);
    }, [team, members, invitations, toast, navigate]);
    const handleResetInvitations = useCallback(() => {
        setMembers('');
    }, []);
    const handleViewTeam = useCallback(() => {
        if (!team) return;
        navigate(links.team(team.id));
    }, [navigate, team]);

    return (
        <FullContainer
        header={<Header />}
        footer={<Footer />}
        >
            <TeamTitle team={name} isTooltipOpen={isTooltipOpen} onViewTeam={handleViewTeam} />
            <Box mt="18px" p="32px" bgColor="#F9FAFB" borderRadius="8px" border="1px solid #F9F9F9">
                <HeadingText>
                Datos del equipo
                </HeadingText>
                <Box mt="25px" mb="32px">
                    <ButtonGroup gap="24px">
                        <Tab id="1" isSelected={tabSelected === "1"} onChange={handleTabClick}>
                        Descripción general
                        </Tab>
                        <Tab id="2" isDisabled={name.length === 0} isSelected={tabSelected === "2"} onChange={handleTabClick}>
                        Miembros del equipo
                        </Tab>
                    </ButtonGroup>
                </Box>
                {tabSelected === "1"
                ? <TeamDescription
                    isAdding={isLoading}
                    name={name} onChangeName={onChangeName}
                    web={web} onChangeWeb={onChangeWeb}
                    description={description} onChangeDescription={onChangeDescription}
                    twitter={twitter} onChangeTwitter={onChangeTwitter}
                    instagram={instagram} onChangeInstagram={onChangeInstagram}
                    linkedin={linkedin} onChangeLinkedin={onChangeLinkedin}
                    logo={logo} onChangeLogo={onChangeLogo}
                    onAdd={handleAddTeam}
                />
                : <TeamMembers
                    isSending={isLoading}
                    members={members} onChangeMembers={onChangeMembers}
                    invitations={invitations}
                    onSend={handleSendInvitations}
                    onReset={handleResetInvitations}
                    locale={locale} onChangeLocale={handleChangeLocale}
                />
                }
            </Box>
            <ModalUnsavedTeam isOpen={isOpenModalUnsavedTeam}
            onClose={handeOnCloseModalUnsavedTeam}
            onSave={handleAddTeam}
            />
            <ModalSavedTeam isOpen={isOpenModalSavedTeam}
            onClose={handeOnCloseModalSavedTeam}
            onAddMembers={() => { setOpenModalSavedTeam(false); setTabSelected("2"); }}
            />
            <ModalSavedData isOpen={isOpenModalSavedMembers}
            onClose={handleOnCloseModalSavedMembers}
            onAddMembers={handleSendInvitations}
            />
        </FullContainer>
    );
};

export default AddTeam;