import { useState, useCallback } from 'react';
import { Button, Icon } from '@common/components';

const PrimaryButton = ({ id, children, icon, onClick }) =>
{
    const [isHovered, setIsHovered] = useState(false);
    const handleMouseEnter = useCallback(()=> { setIsHovered(true); }, [setIsHovered]);
    const handleMouseLeave = useCallback(() => { setIsHovered(false); }, [setIsHovered]);
    return (
        <Button
        id={id}
        variant="secondary" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}
        leftIcon={ <Icon icon={icon} size="20px" color={isHovered ? '#70B37D' : '#6B7798'} /> }
        onClick={onClick}>
        {children}
        </Button>
    );
};

export default PrimaryButton;